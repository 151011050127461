import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router} from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  // showToggle=false;
  // isLoggedIn$: Observable<boolean>;

  constructor(private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private messageService : MessageService,) { }

  ngOnInit() {
    // this.isLoggedIn$ = this.authService.isLoggedIn;
  }
  btnToggle() {
        $('.sidebar').toggleClass('fliph');
        $('.dashboard-section').toggleClass('dashboard-close');
  }
  onLogout() {
    console.log("log out api call");
    this.authService.logout()
    .subscribe(
      (success: any) => {
        window.localStorage.clear();
        this.router.navigateByUrl('logIn');
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
      })
  }
}
