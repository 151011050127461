export const URLS = {
    register: 'apis/api/v1/register/user',
    logOut: 'apis/api/v1/logout',
    verifyMail: 'apis/api/v1/register/mail-verify?token=',
    logIn: 'apis/api/v1/login',
    updateKyc: 'apis/api/v1/register-user/kyc-hub',
    registerKyc: 'apis/api/v1/access/token/kyc-hub',
    forgotPassword: 'apis/api/v1/forgot/password',
    resetPassword: 'apis/api/v1/reset/password-submit?token=',
    fetchBalance: 'apis/api/v1/user-account/balance',
    changePassword: 'apis/api/v1/change/password',
    getUserProfile: 'apis/api/v1/user/profile',
    updateUserProfile: 'apis/api/v1/user/profile',
    sendEnvoy: 'apis/api/v1/strict-send/asset-payment',
    transactionSummary: 'apis/api/v1/transaction_history',
    receiveEnvoy: 'apis/api/v1/strict-receive/asset-payment',
    buyEnvoy: 'apis/api/v1/manage/buy-offer',
    sellEnvoy: 'apis/api/v1/manage/sell-offer',
    tradeHistory: 'apis/api/v1/trade-transaction/history',
    cardPayment: 'apis/api/v1/charge/payment',
    depositPayment: 'apis/api/v1/deposit/currency?amount={amount}&currency={currency}',
    getcurrencyList: 'apis/api/v1/currency/list',
    deposit: 'apis/api/v1/deposit/transactions',
    getConvertFund: 'apis/api/v1/market-price/currency?marketCurrency={marketCurrency}&pairedCurrency={pairedCurrency}'
}



