import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { SignupDetails } from '../models/SignupDetails';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  registerform: FormGroup;
  submitted: boolean;
  SignupData = new SignupDetails();
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router :Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService
  
  ) {}
//   showToatr() {
//     this.toastr.success('Some messages', 'title');
//   }
  emailregex = /^[a-z]+[a-z0-9._+a-z]+@[a-z0-9]+\.[a-z.]{2,}$/
  passwordregex= /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  fullnameregex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
  ngOnInit() {
    this.SignupData = new SignupDetails();
    this.registerform = this.fb.group({
      fullName:['',[Validators.required,Validators.pattern(this.fullnameregex)]],
      email: ['', [Validators.required, Validators.email,Validators.pattern(this.emailregex)]],
      password: ['', [Validators.required, Validators.pattern(this.passwordregex)]],
      confirmPassword:['',Validators.required] 
        },{
          validator: this.MustMatch('password', 'confirmPassword')
        });
    // this.spinner.show();
 
    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 2000);
       
  }
   MustMatch(controlName: string, matchingControlName: string) {
    return (registerform: FormGroup) => {
        const control = registerform.controls[controlName];
        const matchingControl = registerform.controls[matchingControlName];

        // return null if controls haven't initialised yet
        if (!control || !matchingControl) {
          return null;
        }

        // return null if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return null;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}


  onSubmit(SignupDetails) {
    if (this.registerform.valid) {
      this.authService.Signup(this.registerform.value);
    }
    this.spinner.show();
    this.authService.signUp(SignupDetails).subscribe(
      (success: any) => {
        this.spinner.hide();
        this.toastr.success(success.message);
      }, 
      (error: HttpErrorResponse) => {
        console.log(error);
        // this.toastr.error(error.message); 
        this.spinner.hide();
        this.messageService.showErrorMessage(error);
        this.router.navigate(['/signup'])
      })
  }
}



