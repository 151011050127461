export class UserProfileModel{
    address: string;
    accountId: string;
    dateOfBirth: any;
    fullName: string;
    email: string;
}

export class UpdateProfileModel{
    address: string;
    dateOfBirth: any;
    fullName: string;
    email: string;
    accountId: string;
}