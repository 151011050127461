import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Observable, from, of, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../common/models/user';
import { SignupDetails } from '../common/models/SignupDetails';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Forgotpassword } from '../common/models/forgotpassword';
import { KycDetails } from '../common/models/kycDetails';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { map, mergeMap, concatMap, delay } from 'rxjs/operators';
import { MyBalanceModel, BalanceDataModel } from 'src/app/common/dashboard/dashboard.model';
import { UserProfileModel, UpdateProfileModel } from 'src/app/right-sidebar/profile/profile.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { URLS } from 'src/app/core/constant/app.constant';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
const httpOptions = {

  headers: new HttpHeaders()
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user() {
    throw new Error("Method not implemented.");
  }

  nameChange: Subject<string> = new Subject<string>();
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loggedOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private Forgot: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private KYC: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private kycRegistration: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private nameSource = new BehaviorSubject(" ");
  currentUser = this.nameSource.asObservable();


  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private Http: HttpClient) { }

  changeName(message: string) {
    this.nameSource.next(message)
  }

  login(user: User) {
    if (user.email !== '' && user.password !== '') {
      this.loggedIn.next(true);
      this.router.navigate(['/dashboard']);
    }
  }
  Kyc(user: KycDetails) {
    if ((user.firstName !== '' && user.lastName !== '') && user.countryCode !== '' && user.email !== '') {
    }
  }

  get iskycRegistration() {
    return this.kycRegistration.asObservable();
  }
  get isKyc() {
    return this.KYC.asObservable();
  }
  get isLoggedOut() {
    return this.loggedOut.asObservable();
  }
  forgot(user: Forgotpassword) {
    if ((user.oldpassword !== '' && user.newpassword !== '') && (user.confirmpassword !== '')) {
      this.Forgot.next(true);
      this.router.navigate(['/logIn']);
    }
  }
  token(user: any) {
    if (user.email !== '') {
      this.kycRegistration.next(true);
      this.router.navigate(['/kyc']);
    }
  }
  get isForgot() {
    return this.Forgot.asObservable();
  }
  Signup(user: SignupDetails) {
    if ((user.fullName !== '' && user.email !== '') && (user.password !== '' && user.confirmPassword !== '')) {
      this.loggedOut.next(true);
      this.router.navigate(['/logIn']);
    }

  }
  logout() {
    this.spinner.show();
    setTimeout(() => {
      /** spinner ends after 1 seconds */
      this.spinner.hide();
    }, 1000);
    let logOutUrl = environment.host + URLS.logOut;
    return this.Http.delete<any>(logOutUrl);
  }


  signUp(SignupDetails) {
    console.log(JSON.stringify(SignupDetails))
    let signUpUrl = environment.host + URLS.register;
    return this.Http.post<any>(signUpUrl, SignupDetails);
  }
  mailVerify() {

    let token = localStorage.getItem('token');
    let mailVerifyUrl = environment.host + URLS.verifyMail + token;
    return this.Http.get<any>(mailVerifyUrl);
  }
  loginuser(User) {
    let loginuserUrl = environment.host + URLS.logIn;
    return this.Http.post<any>(loginuserUrl, User)
  }

  kycuser(KycDetails) {
    let token = localStorage.getItem('token');
    let kycUrl = environment.host + URLS.updateKyc;
    return this.Http
      .post<any>(
        kycUrl,
        KycDetails,
        {
          headers: new HttpHeaders()
            .set('xAuthToken', token),
          observe: 'response'
        }
      )
      .pipe(
        map(res => {
          return res;
        })
      );
    return this.Http.post<any>(kycUrl, KycDetails);
  }
  tokenuser(kycRegistrationDetails) {
    let tokenUrl = environment.host + URLS.registerKyc;
    return this.Http.post<any>(tokenUrl, kycRegistrationDetails);
  }
  forgotPassword(email) {
    let forgotPasswordUrl = environment.host + URLS.forgotPassword;
    return this.Http.put<any>(forgotPasswordUrl, email)
  }
  resetPassword(data) {
    console.log(data);
    let token = localStorage.getItem('token');
    let resetPasswordUrl = environment.host + URLS.resetPassword + token;
    return this.Http.put<any>(resetPasswordUrl, data)
  }

  getBalance(): Observable<MyBalanceModel> {
    let balanceUrl = environment.host + URLS.fetchBalance;
    return this.Http.get<MyBalanceModel>(balanceUrl);
  }

  changePassword(data) {
    console.log(data);
    let changePasswordUrl = environment.host + URLS.changePassword;
    return this.Http.put<any>(changePasswordUrl, data)
  }

  userProfile(): Observable<UpdateProfileModel> {
    let userProfileUrl = environment.host + URLS.getUserProfile;
    return this.Http.get<UpdateProfileModel>(userProfileUrl);
  }
  updateUserProfile(updatedUserDetails) {
    let updateprofileUrl = environment.host + URLS.updateUserProfile;
    return this.Http.put(updateprofileUrl, updatedUserDetails)
  }

  sendEnvoy(sendPayment) {
    let sendEnvoyUrl = environment.host + URLS.sendEnvoy;
    return this.Http.post<any>(sendEnvoyUrl, sendPayment);
  }

  receiveEnvoy(receivePayment) {
    let receiveEnvoyUrl = environment.host + URLS.receiveEnvoy;
    return this.Http.post<any>(receiveEnvoyUrl, receivePayment);
  }

  transactionSummary(transactionRequest) {
    let transactionSummaryUrl = environment.host + URLS.transactionSummary;
    return this.Http.post<any>(transactionSummaryUrl, transactionRequest);
  }

  buyEnvoy(buyEnvoyRequest, accountId) {
    let buyEnvoyUrl = environment.host + URLS.buyEnvoy;
    return this.Http.post<any>(buyEnvoyUrl, buyEnvoyRequest);
  }

  sellEnvoy(sellEnvoyRequest, accountId) {
    let sellEnvoyUrl = environment.host + URLS.sellEnvoy;
    return this.Http.post<any>(sellEnvoyUrl, sellEnvoyRequest);
  }

  tradeHistory(tradeRequest) {
    let tradeHistoryUrl = environment.host + URLS.tradeHistory;
    return this.Http.post<any>(tradeHistoryUrl, tradeRequest);
  }

  deposit(amount: string, currency: string): Observable<any> {
    let depositUrl = environment.host + URLS.depositPayment;
    depositUrl = depositUrl.replace("{amount}", amount);
    depositUrl = depositUrl.replace("{currency}", currency);
    return this.Http.post<any>(depositUrl, amount);
  }

  chargePayment(paymentDetails){
    let cardPaymentUrl = environment.host + URLS.cardPayment;
    return this.Http.post(cardPaymentUrl, paymentDetails);
  }

  getCurrencyList(): Observable<any> {
    let currencyListUrl = environment.host + URLS.getcurrencyList;
    return this.Http.get<any>(currencyListUrl);
  }

  convertFunds(marketCurrency: string, pairedCurrency: string): Observable<any> {
    let convertFundUrl = environment.host + URLS.getConvertFund;
    convertFundUrl = convertFundUrl.replace("{marketCurrency}", marketCurrency);
    convertFundUrl = convertFundUrl.replace("{pairedCurrency}", pairedCurrency);
    return this.Http.get<any>(convertFundUrl);
  }

  depositTransactionSummary(transactionRequest) {
    let deposittransactionSummaryUrl = environment.host + URLS.deposit;
    return this.Http.post<any>(deposittransactionSummaryUrl, transactionRequest);
  }



//   chargeCard(token, amount) {
//     const httpOptions = {
//       headers: new HttpHeaders({
//         'token': token,
//         'amount': amount;
//       })
//     };
// console.log(httpOptions);
//     let cardPaymentUrl = environment.host + URLS.cardPayment;
//     return this.Http.post(cardPaymentUrl, httpOptions);
    // this.Http.post('https://dev.envoy.finance:2312/apis/api/v1/charge/payment', {}, { headers: headers })
    //   .subscribe(resp => {
    //     console.log(resp);
    //   })
//   }

// }

}