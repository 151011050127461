import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthenticatedHttpService implements HttpInterceptor {
    constructor(
        public router: Router,
        private toastr: ToastrService,
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const error: any = 'Server Error';
        const token: any = localStorage.getItem('authToken');
        console.log("hiii this is my authT",token);
        if (token) {
            const clonedRequest = req.clone({ headers: req.headers.set('authToken', token) }) ;
            return next.handle(clonedRequest).pipe(tap(event => {
            },
            err => {
                if (err instanceof HttpErrorResponse && (err.status === 401)) {
    
                    setTimeout(() => this.toastr.error('Your session has been expired. Please try again.'))
                    window.localStorage.clear();
                    this.router.navigateByUrl('logIn');
                    location.reload();
                } else if (err.status === 0) {
                    window.localStorage.clear();
                    this.router.navigateByUrl('logIn');
                }
            }));

        }
        return next.handle(req) || error;
    }
}