<head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<div class="container-fluid">

    <div class="logo">
        <div class="logo-img">
            <img src="assets/icons/logo.png" alt="">
        </div>
    </div>
    <div class="logo-1">
        <div class="logo-img-1">
            <img src="assets/icons/Asset 1.png" alt="">
        </div>
    </div>
    <div class="logo-2">
        <div class="logo-img-2">
            <img src="assets/icons/Group 381@2x.png" alt="">
        </div>
    </div>
    <div class="border-form">
        <div class="heading-1 text-center"><b>Email Verification</b></div>
        <div class="mt-4"> 
            <h3 class="text-center">Your email has been successfully<br> verified</h3>
            <p class="text-center mt-4">Click the below button to login</p>
        </div>
        <div>
            <!-- <button class="btn-2 mt-5" (click)="verifyMail()">Continue</button> -->
            <input type="button" [disabled]="!mailVerify" class="btn-2 mt-5" (click)="verifyMail()" value="Continue">
       </div>
    </div>
