<app-sidebar></app-sidebar>
<app-header></app-header>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="container-fluid p-3  pr-4 height-100vh pt-4">
<div class="logo-2">
<div class="logo-img-2">
<img src="assets/icons/Group 381@2x.png" alt="">
</div>
</div>
<div class="border-form">
<div class="heading-1"><b>Change Password</b></div>
<div class="box">
<form #changeForm="ngForm">
<div class="input-container-2 form-group">
<input type="password" [(ngModel)]="changePasswordData.oldPassword" name="oldPassword" #oldPassword="ngModel"
pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$^&*-+_;./>:}/|]).{8,64}$" required />
<label>Old Password</label>
<div *ngIf="oldPassword.invalid && oldPassword.touched">
<div *ngIf="oldPassword.errors.pattern" class="text-danger color-primary">
Password should be 8-64 character long and it should contain atleast one
uppercase, one lowercase , one number and one special character
</div>
<div *ngIf="oldPassword.errors.required" class="text-danger color-primary">
Old Password is required
</div>
</div>
</div>

<div class="input-container-3">
<input type="password" [(ngModel)]="changePasswordData.newPassword" name="newPassword" #newPassword="ngModel"
pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$^&*-+_;./>:}/|]).{8,64}$" required />
<label>New Password</label>
<div *ngIf="newPassword.invalid && newPassword.touched">
<div *ngIf="newPassword.errors.pattern" class="text-danger color-primary">
Password should be 8-64 character long and it should contain atleast one
uppercase, one lowercase , one number and one special character
</div>
<div *ngIf="newPassword.errors.required" class="text-danger color-primary">
New Password is required
</div>
</div>
</div>

<div class="input-container-4 form-group">
<input type="password" [(ngModel)]="changePasswordData.confirmPassword" name="confirmPassword"
#confirmPassword="ngModel" autocomplete="off" required />
<label>Confirm New Password</label>
<div *ngIf="confirmPassword.touched">
<div *ngIf="confirmPassword.errors?.required" class="text-danger color-primary">
Confirm Password is required
</div>
<div
*ngIf="confirmPassword.touched && (changePasswordData.newPassword != changePasswordData.confirmPassword)">
<div class="text-danger color-primary">Passwords do not match</div>
</div>
</div>
</div>
<div class="col-md-6 ">
<button class="btn-1" type="submit" [disabled]="!changeForm.valid"
(click)="changePassword(changePasswordData)">Submit</button>
</div>
</form>
</div>
</div>

</div>