<span class="button-left text-right own-toggle-btn"  (click)="btnToggle()"><span>
  <svg width="14.351" height="8.271" viewBox="0 0 14.351 8.271">
    <g transform="translate(-34.977 -41.352)">
      <path class="a"
        d="M42.173,49.623h0a1.068,1.068,0,0,1-.752-.3l-6.126-6.14A1.072,1.072,0,0,1,36.8,41.662l5.369,5.388,5.369-5.388a1.068,1.068,0,0,1,1.509,1.509l-6.126,6.155A1.068,1.068,0,0,1,42.173,49.623Z"
        transform="translate(0)" />
    </g>
  </svg>
</span></span>
<!-- <a *ngIf="showToggle" href="#" class="button-left text-right own-toggle-btn" (click)="btnToggle()"><span>
  <svg width="17.7" height="17.772" viewBox="0 0 17.7 17.772">

    <g transform="translate(-13.5 -13.1)">
      <path class="a"
        d="M25.5,14.935H14.418a.917.917,0,0,1-.918-.918h0a.917.917,0,0,1,.918-.918H25.5a.917.917,0,0,1,.918.918h0A.917.917,0,0,1,25.5,14.935Z"
        transform="translate(0)" />
      <path class="a"
        d="M30.282,47.935H14.418a.917.917,0,0,1-.918-.918h0a.917.917,0,0,1,.918-.918H30.282a.917.917,0,0,1,.918.918h0A.917.917,0,0,1,30.282,47.935Z"
        transform="translate(0 -25.031)" />
      <path class="a"
        d="M25.5,80.935H14.418a.917.917,0,0,1-.918-.918h0a.917.917,0,0,1,.918-.918H25.5a.917.917,0,0,1,.918.918h0A.917.917,0,0,1,25.5,80.935Z"
        transform="translate(0 -50.063)" />
    </g>
  </svg>
</span></a> -->
<div class="main" >
<aside>
  <div class="sidebar left ">
    <!-- <div class="user-panel">
            <div class="pull-left image">
              <img src="http://via.placeholder.com/160x160" class="rounded-circle" alt="User Image">
            </div>
            <div class="pull-left info">
              <p>bootstrap develop</p>
              <a href="#"><i class="fa fa-circle text-success"></i> Online</a>
            </div>
          </div> -->
    <ul class="list-sidebar bg-defoult">
      <!-- <li> <a href="#" data-toggle="collapse" data-target="#dashboard" class="collapsed active" > <i class="fa fa-th-large"></i> <span class="nav-label"> Dashboards </span> <span class="fa fa-chevron-left pull-right"></span> </a>
            <ul class="sub-menu collapse" id="dashboard">
              <li class="active"><a href="#">CSS3 Animation</a></li>
              <li><a href="#">General</a></li>
              <li><a href="#">Buttons</a></li>
              <li><a href="#">Tabs & Accordions</a></li>
              <li><a href="#">Typography</a></li>
              <li><a href="#">FontAwesome</a></li>
              <li><a href="#">Slider</a></li>
              <li><a href="#">Panels</a></li>
              <li><a href="#">Widgets</a></li>
              <li><a href="#">Bootstrap Model</a></li>
            </ul>
          </li> -->
      <li> <a routerLink="/dashboard" [routerLinkActiveOptions]={exact:true} routerLinkActive="activeLink" class="collapsed active own-icon"> <span
            class="own-svg">
            <svg width="17" height="17" viewBox="0 0 17 17">

              <g transform="translate(-3 -4)">
                <path class="a"
                  d="M12,4h8v6H12V4m0,17V11h8V21H12M3,21V15h8v6H3m0-7V4h8V14H3M4,5v8h6V5H4m9,0V9h6V5H13m0,7v8h6V12H13M4,16v4h6V16Z" />
              </g>
            </svg>
          </span><span class="nav-label"> Dashboard </span></a></li>
      <li> <a routerLink="/payment-method" routerLinkActive="activeLink" class="collapsed active own-icon"><span class="own-svg"><svg width="20.8" height="20.8"
              viewBox="0 0 20.8 20.8">
              <g transform="translate(-3 -3)">
                <g transform="translate(3 3)">
                  <path class="a"
                    d="M23.3,3H3.5a.5.5,0,0,0-.5.5V7.457a.5.5,0,0,0,.5.5H5.971V23.3a.5.5,0,0,0,.5.5H20.333a.5.5,0,0,0,.5-.5V7.952H23.3a.5.5,0,0,0,.5-.5V3.5A.5.5,0,0,0,23.3,3ZM8.943,22.81H6.962V5.476H8.943Zm2.971,0H9.933V5.476h1.981Zm7.924,0H12.9V5.476h6.933ZM22.81,6.962H20.829V4.981a.5.5,0,0,0-.5-.5H6.467a.5.5,0,0,0-.5.5V6.962H3.99V3.99H22.81Z"
                    transform="translate(-3 -3)" />
                  <path class="a"
                    d="M27.5,15.962h2.971a.5.5,0,0,0,.5-.5V12.5a.5.5,0,0,0-.5-.5H27.5a.5.5,0,0,0-.5.5v2.971A.5.5,0,0,0,27.5,15.962Zm.5-2.971h1.981v1.981H27.99Z"
                    transform="translate(-15.114 -7.543)" />
                </g>
              </g>
            </svg></span><span class="nav-label">Payments</span></a> </li>
      <li> <a routerLink="/receipts" routerLinkActive="activeLink"  class="collapsed own-icon active"><span
            class="own-svg"><svg width="21.6" height="21.6" viewBox="0 0 21.6 21.6">

              <path class="a"
                d="M21.6,3.439A3.442,3.442,0,0,0,18.161,0H3.439a3.438,3.438,0,0,0-.315,6.861v14.2a.54.54,0,0,0,.919.385l2.025-2,1.97,1.991a.539.539,0,0,0,.382.16h0a.541.541,0,0,0,.381-.158l2-1.994,2,1.993a.539.539,0,0,0,.763,0l2-1.993,2,1.994a.54.54,0,0,0,.922-.382V6.861A3.442,3.442,0,0,0,21.6,3.439Zm-20.52,0A2.36,2.36,0,0,1,3.123,1.1v4.67A2.36,2.36,0,0,1,1.08,3.439ZM15.94,18.3a.54.54,0,0,0-.763,0l-2,1.993-2-1.993a.54.54,0,0,0-.763,0L8.424,20.294,6.456,18.3a.54.54,0,0,0-.763,0L4.2,19.769V1.08H17.4V19.758ZM18.477,5.773V1.1a2.356,2.356,0,0,1,0,4.67Z" />
              <path class="a" d="M36.325,13.375h-8.91a.54.54,0,0,0,0,1.08h8.91a.54.54,0,1,0,0-1.08Z"
                transform="translate(-21.07 -10.486)" />
              <path class="a" d="M36.325,27.125h-8.91a.54.54,0,1,0,0,1.08h8.91a.54.54,0,1,0,0-1.08Z"
                transform="translate(-21.07 -21.266)" />
              <path class="a" d="M27.415,41.955H31.33a.54.54,0,0,0,0-1.08H27.415a.54.54,0,0,0,0,1.08Z"
                transform="translate(-21.07 -32.046)" />
              <path class="a" d="M36.325,54.625h-8.91a.54.54,0,0,0,0,1.08h8.91a.54.54,0,1,0,0-1.08Z"
                transform="translate(-21.07 -42.826)" />
              <path class="a" d="M34.192,68.375H27.415a.54.54,0,0,0,0,1.08h6.777a.54.54,0,1,0,0-1.08Z"
                transform="translate(-21.07 -53.606)" />
            </svg></span> <span class="nav-label">Receipts</span> </a></li>
      <li> <a routerLink="/trading" routerLinkActive="activeLink" class="collapsed own-icon active"><span class="own-svg"><svg height="18"
              viewBox="0 0 25.714 18">
              <g transform="translate(140.7 -374.41)">
                <g transform="translate(-140.7 374.41)">
                  <path class="a"
                    d="M17.857,27.714v-.857h.429a.429.429,0,0,0,.429-.429v-2.5L20.092,22.4a.42.42,0,0,0,.337.176h.429v.857a.429.429,0,1,0,.857,0v-.857h.429a.429.429,0,0,0,.429-.429v-.79l2.143.777v1.727a.429.429,0,0,0,.429.429h.429v.857a.429.429,0,1,0,.857,0v-.857h.429a.429.429,0,0,0,.429-.429V21.8l1.524-1.017a.429.429,0,1,0-.475-.713l-1.048.7v-.343A.429.429,0,0,0,26.857,20h-.429v-.857a.429.429,0,0,0-.857,0V20h-.429a.429.429,0,0,0-.429.429v.79l-2.143-.777V18.714a.429.429,0,0,0-.429-.429h-.429v-.857a.429.429,0,0,0-.857,0v.857h-.429a.429.429,0,0,0-.429.429v2.5l-1.377,1.531a.42.42,0,0,0-.337-.176h-.429v-.857a.429.429,0,1,0-.857,0v.857h-.429a.429.429,0,0,0-.429.429v.214l-.857-.643V20.429A.429.429,0,0,0,14.857,20h-.429v-.857a.429.429,0,0,0-.857,0V20h-.429a.429.429,0,0,0-.429.429v2.554L11.1,24.864a.429.429,0,0,0,.651.557l1.083-1.264a.429.429,0,0,0,.306.129h.429v.857a.429.429,0,1,0,.857,0v-.857h.429a.429.429,0,0,0,.429-.429v-.214l.857.643v2.143a.429.429,0,0,0,.429.429H17v.857a.429.429,0,0,0,.857,0Zm7.714-6.857h.857v2.571h-.857ZM14.429,23.429h-.857V20.857h.857Zm6.429-4.286h.857v2.571h-.857ZM17,23.429h.857V26H17Z"
                    transform="translate(-7.143 -15.286)" />
                  <path class="a"
                    d="M27.286,27.143h-.857V13.96a.961.961,0,0,0-.96-.96H4.246a.961.961,0,0,0-.96.96V27.143H2.429A.429.429,0,0,0,2,27.571V29.4A1.6,1.6,0,0,0,3.6,31H26.116a1.6,1.6,0,0,0,1.6-1.6v-1.83A.429.429,0,0,0,27.286,27.143ZM4.143,13.96a.1.1,0,0,1,.1-.1H25.469a.1.1,0,0,1,.1.1V27.143H17.429A.429.429,0,0,0,17,26.714H12.714a.429.429,0,0,0-.429.429H4.143Zm9,13.611h3.429v.608a.249.249,0,0,1-.249.249H13.392a.249.249,0,0,1-.249-.249ZM26.857,29.4a.742.742,0,0,1-.741.741H3.6a.742.742,0,0,1-.741-.741V28h9.429v.179a1.108,1.108,0,0,0,1.106,1.107h2.931a1.108,1.108,0,0,0,1.106-1.107V28h9.429Z"
                    transform="translate(-2 -13)" />
                </g>
              </g>
            </svg></span> <span class="nav-label">Trading</span></a> </li>
      <!-- <li> <a routerLink="/profile" routerLinkActive="activeLink" class="collapsed own-icon active"><span
            class="own-svg"><svg width="22.1" height="18.961" viewBox="0 0 22.1 18.961">
              <g transform="translate(-6 -10)">
                <path class="a"
                  d="M26.076,27.956a8.933,8.933,0,0,0-6.73-8.145,5.148,5.148,0,1,0-4.341,0,8.933,8.933,0,0,0-6.729,8.145H6v1H28.1v-1ZM13.032,15.148a4.144,4.144,0,1,1,4.144,4.144A4.148,4.148,0,0,1,13.032,15.148ZM9.28,27.956a7.911,7.911,0,0,1,15.79,0Z" />
              </g>
            </svg></span> <span class="nav-label">Profile</span></a>

      </li> -->

 <li> <a routerLink="/profile" routerLinkActive="activeLink" class="collapsed own-icon active"><span
  class="own-svg"><svg width="22.1" height="18.961" viewBox="0 0 22.1 18.961">
    <g transform="translate(-6 -10)">
      <path class="a"
        d="M26.076,27.956a8.933,8.933,0,0,0-6.73-8.145,5.148,5.148,0,1,0-4.341,0,8.933,8.933,0,0,0-6.729,8.145H6v1H28.1v-1ZM13.032,15.148a4.144,4.144,0,1,1,4.144,4.144A4.148,4.148,0,0,1,13.032,15.148ZM9.28,27.956a7.911,7.911,0,0,1,15.79,0Z" />
    </g>
  </svg></span> <span class="nav-label">Profile</span></a>

</li>
      <li> <a routerLink="/settings" routerLinkActive="activeLink" class="collapsed own-icon active"><span
            class="own-svg"><svg width="22.415" height="22.414" viewBox="0 0 22.415 22.414">
              <g transform="translate(-2.5 -2.5)">
                <g transform="translate(2.5 2.5)">
                  <path class="a"
                    d="M23.828,11.4,22.387,11l-.614-1.512.732-1.3a1.5,1.5,0,0,0-.236-1.8L21.041,5.169a1.5,1.5,0,0,0-1.8-.236l-1.3.732-1.512-.614-.4-1.441A1.538,1.538,0,0,0,14.593,2.5H12.869a1.489,1.489,0,0,0-1.441,1.11l-.4,1.441-1.512.614-1.3-.732a1.5,1.5,0,0,0-1.8.236L5.169,6.4a1.5,1.5,0,0,0-.236,1.8l.732,1.3L5.051,11l-1.441.4A1.538,1.538,0,0,0,2.5,12.845v1.724A1.469,1.469,0,0,0,3.61,16.01l1.441.4.614,1.512-.732,1.3a1.5,1.5,0,0,0,.236,1.8L6.4,22.245a1.5,1.5,0,0,0,1.8.236l1.3-.732L11,22.363l.4,1.441a1.538,1.538,0,0,0,1.441,1.11h1.724A1.489,1.489,0,0,0,16.01,23.8l.4-1.441,1.512-.614,1.3.732a1.5,1.5,0,0,0,1.8-.236l1.228-1.228a1.5,1.5,0,0,0,.236-1.8l-.732-1.3.614-1.512,1.441-.4a1.538,1.538,0,0,0,1.11-1.441V12.845A1.461,1.461,0,0,0,23.828,11.4Zm-.165,3.189a.257.257,0,0,1-.165.236l-2.031.543-1.086,2.645,1.039,1.842a.218.218,0,0,1-.047.283l-1.228,1.228a.251.251,0,0,1-.283.047l-1.842-1.039-2.645,1.086L14.805,23.5a.235.235,0,0,1-.236.165H12.845a.284.284,0,0,1-.236-.165l-.543-2.031L9.42,20.379,7.578,21.419a.218.218,0,0,1-.283-.047L6.066,20.143a.251.251,0,0,1-.047-.283l1.039-1.842L5.972,15.372l-2.031-.567a.235.235,0,0,1-.165-.236V12.845a.257.257,0,0,1,.165-.236l2.031-.567L7.058,9.4,6.019,7.578a.218.218,0,0,1,.047-.283L7.295,6.066a.251.251,0,0,1,.283-.047L9.42,7.058l2.645-1.086.567-2.031a.235.235,0,0,1,.236-.165h1.724a.284.284,0,0,1,.236.165L15.4,5.972l2.645,1.11,1.842-1.039a.218.218,0,0,1,.283.047L21.4,7.318a.251.251,0,0,1,.047.283L20.4,9.444l1.063,2.622,2.031.567a.235.235,0,0,1,.165.236Z"
                    transform="translate(-2.5 -2.5)" />
                  <path class="a"
                    d="M36.251,32A4.251,4.251,0,1,0,40.5,36.251,4.264,4.264,0,0,0,36.251,32Zm0,7.227a2.976,2.976,0,1,1,2.976-2.976A2.979,2.979,0,0,1,36.251,39.227Z"
                    transform="translate(-25.032 -25.032)" />
                </g>
              </g>
            </svg></span> <span class="nav-label">Deposit</span></a>

      </li>
      <li> <a routerLink="logout" (click)="onLogout()" routerLinkActive="activeLink" class="collapsed active own-icon"><span
            class="own-svg">
            <svg width="22.59" height="18" viewBox="0 0 22.59 18">
              <g transform="translate(-14 -20)">
                <g transform="translate(14 20)">
                  <path class="a" d="M37,37.99v1.2H50.38l-3.42,3.42.84.87,4.89-4.89L47.8,33.7l-.84.87,3.42,3.42Z"
                    transform="translate(-30.1 -29.59)" />
                  <path class="a" d="M29,33.2H27.8v3.6H15.2V21.2H27.8v3.6H29V20H14V38H29Z"
                    transform="translate(-14 -20)" />
                </g>
              </g>
            </svg>
          </span> <span class="nav-label">Logout</span></a>

      </li>
    </ul>
  </div>
</aside>
</div>
