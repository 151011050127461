<app-header></app-header>
<app-sidebar></app-sidebar>

<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="container-fluid">
  <div class="border-form">
    <div class="heading-1"><b>KYC Details</b></div>
    <div class="box">
      <form [formGroup]="kycform" (ngSubmit)="onSubmit(KycData)">
      <div class="input-container-2">		
        <input type="text" formControlName="firstName" [(ngModel)]="KycData.firstName" autocomplete="off" required/>
        <label>First Name</label>
        <div class="text-danger color-primary"
        *ngIf="kycform.controls['firstName'].touched && !kycform.controls['firstName'].valid">
        <span *ngIf="kycform.controls['firstName'].hasError('required') ">
        FirstName is required
        </span>
       
    </div>
      
        
      </div>
      <div class="input-container-3">		
        <input type="text" formControlName="lastName" [(ngModel)]="KycData.lastName" autocomplete="off" required/>
        <label>Last Name</label>
        <div class="text-danger color-primary"
        *ngIf="kycform.controls['lastName'].touched && !kycform.controls['lastName'].valid">
        <span *ngIf="kycform.controls['lastName'].hasError('required') ">
           LastName is required
        </span>
        
        
    </div>


      </div>

      <div class="input-container-4">		
        <input type="email" formControlName="email" [(ngModel)]="KycData.email" autocomplete="off" required/>
        <label>Email</label>
        <div class="text-danger color-primary"
        *ngIf="kycform.controls['email'].touched && !kycform.controls['email'].valid">
        <span *ngIf="kycform.controls['email'].hasError('required') ">
            Email is required
        </span>
        <span *ngIf="kycform.controls['email'].hasError('pattern') ">
            Email is invalid
        </span>
        
        
    </div>


      </div>
      <div class="input-container-5">		
        <input type="text" formControlName="countryCode" [(ngModel)]="KycData.countryCode" autocomplete="off" required/>
        <label>countryCode</label>
        <div class="text-danger color-primary"
        *ngIf="kycform.controls['countryCode'].touched && !kycform.controls['countryCode'].valid">
        <span *ngIf="kycform.controls['countryCode'].hasError('required') ">
            countryCode is required
        </span>
        
        
    </div>


      </div>
        
    <!-- <label class="container-1">
      <input type="checkbox" checked="checked">
      <span class="checkmark"></span>
    </label> -->
    <!-- <label for="remember_me" class="remember">Remember me</label> -->
    <!-- <label for="forgotpassword" routerLink="" routerLinkActive="active" class="f_psd">Forgot password?</label> -->
    <div class="col-md-6 ">
      <!-- <button class="btn">Sign In</button> -->
      <button  class="btn-1" >Submit</button>
      </div>
    </form>	
  </div>

      <!-- <div class="input_3"></div>
      <div class="or">Or</div>
      <div class="input_4"></div> 
     <a href="https://myaccount.google.com/"> <button class="btn-2" >Sign in with Google</button></a>
      <div class="logo-3">
        <div class="logo-img-3">
          <img src="assets/icons/google-hangouts.png" alt="">
        </div>
      
  </div> -->
  
</div>