export class SignupDetails {
    fullName:string;
    email: string;
    password: string;
    confirmPassword:string;
    constructor(){
      this.fullName = null;
      this.email = null;
      this.password = null;
      this.confirmPassword = null;
    }
  }
