<ngx-spinner>                 
 <p style="font-size: 20px; color:#37BFFF">Loading...</p>   
</ngx-spinner>
<div class="bg-dark-blue">
<div class="sidebar-wrapper">
<div class="dashboard-section">
<router-outlet></router-outlet>
</div>
</div>
</div>
