<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="container-fluid p-3 payment-method pr-4 height-100vh pt-4">
    <div class="row text-center">
        <div class="col-md-12 text-center">
            <div class="wrapper-box pt-5 pl-5 pr-5 pb-5">
                <!-- <div class="profile-img">
                    <img src="assets/icons/user.png" alt="profile-pic">
                </div> -->
                <div class="profile-details">
                    <p class="profile-user-name"></p>
                   
                    <input type="text" [(ngModel)]="updatedUserDetails.fullName" id="username"
                        [disabled]="!userName" class="form-control userNameblock"> 
                        <!-- <span class="name" (click)="editUserProfile()" *ngIf="!userName">Change  Name</span> -->
                           <i (click)="editUserProfile()" *ngIf="!userName" class="fas fa-edit name cursor-pointer edit-icons"></i>
                    <button *ngIf="userName" style="background-color: #37BFFF; border: none;
                border-radius: 3px; margin-left: 10px;margin-top: 10px;" (click)="updateUserProfile()">Update</button>    
                    <!-- <p class="user-name"><input class="user-name" type="text" [disabled]="!userName"
                            [(ngModel)]="updatedUserDetails.fullName"><span (click)="editUserProfile()"
                            *ngIf="!userName" style="color: #37BFFF; font-size: 12px; margin-left: 10px;">Change
                            name</span> <button *ngIf="userName" style="background-color: #37BFFF; border: none;
                    border-radius: 3px;
                    margin-left: 10px;" (click)="updateUserProfile()">Update</button></p> -->
                    <p class="f-size">Last login time: 2020-10-05 12:33:14</p>
                    <p class="f-size">IP: 180.152.1.192</p>
                </div>
                <div class="center mt-5">
                    <div class="row">
                        <div class="col-md-6 pl-5">
                            <div class="profile-form">
                                <div class="profile-card">
                                    <label>Login Password</label><span routerLink="/change-pswd">Change Password</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pl-5">
                            <div class="profile-form">
                               <div class="profile-card">
                                    <label>Linked Cards</label><span><span>See all</span> <span
                                            class="color">xxxxx-4589</span> </span>
                                </div>
                                <div class="visa">
                                    <img src="assets/icons/Visa-Logo-Tagline-1280x720.png">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6 pl-5">
                            <div class="profile-form">
                                <div class="profile-card">
                                    <label>Public Account Id</label><span class="public-acc-id"
                                        >{{updatedUserDetails.accountId}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pl-5">
                            <div class="profile-form">
                                <div class="profile-card">
                                    <label>KYC</label><span routerLink="/kycRegistration">Update KYC</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6 pl-5">
                            <div class="profile-form">
                                <!-- <div class="profile-card address"> -->
                                    <label>Address</label><span class="position-text" (click)="editUserAddress()" *ngIf="!address">Change
                                        address</span>
                                        <input type="text" [(ngModel)]="updatedUserDetails.address"
                                        [disabled]="!address" class="form-control user-dob">
                                    <button *ngIf="address" style="background-color: #37BFFF; border: none;
                                border-radius: 3px; margin-left: 10px;margin-top: 10px;" (click)="updateUserProfile()">Update</button>
                                    <!-- <input class="user-address" type="text" [disabled]="!address"
                                        [(ngModel)]="updatedUserDetails.address">
                                    <button *ngIf="address" style="background-color: #37BFFF; border: none;
                                border-radius: 3px; margin-left: 10px;" (click)="updateUserProfile()">Update</button> -->
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6 pl-5">
                            <div class="profile-form">
                                <!-- <div class="profile-card address"> -->
                                    <label>DOB</label><span class="position-text" (click)="editUserDob()" *ngIf="!dob">Change DOB</span>
                                    <input type="text" [(ngModel)]="selectedDate" placeholder="Datepicker" placement="top"
                                       [disabled]="!dob" class="form-control user-dob" bsDatepicker [bsConfig]="dpConfig">
                                    <button *ngIf="dob" style="background-color: #37BFFF; border: none;
                                border-radius: 3px; margin-left: 10px;margin-top: 10px;" (click)="updateUserProfile()">Update</button>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>