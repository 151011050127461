import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { UserProfileModel, UpdateProfileModel } from './profile.model';
import { NgxSpinnerService } from "ngx-spinner";
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { getFullYear } from 'ngx-bootstrap/chronos/public_api';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  userData: UserProfileModel;
  updatedUserDetails: UpdateProfileModel;
  userName: boolean;
  address: boolean;
  dob: boolean;
  dateOfBirth: Date;
  updatedUserData: UserProfileModel;
  myDateValue: Date;
  selectedDate: any;
  formattedDob: any;
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  constructor(private authService: AuthService,
    private toastr: ToastrService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService, private readonly datePipe: DatePipe) {
    this.userData = new UserProfileModel();
    this.updatedUserDetails = new UpdateProfileModel();
    this.updatedUserData = new UserProfileModel();
    this.dpConfig.containerClass = 'theme-dark-blue';
  }

  ngOnInit() {
    this.authService.currentUser.subscribe(message => this.updatedUserDetails.fullName = message)
    this.userProfile();
    this.spinner.show();
    this.myDateValue = new Date();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

  userProfile() {

    this.authService.userProfile().subscribe(
      (success: any) => {
        console.log(success, 'success res')
        this.updatedUserDetails = success.responseObject;
        this.formattedDob = success.responseObject.dateOfBirth
        if (this.formattedDob) {
          let dob = new Date(this.formattedDob)
          console.log('ts' + dob)
          const date = this.datePipe.transform(dob, 'MM/dd/yyyy')
          this.selectedDate = date
          console.log('dob' + date)

        }
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        // this.messageService.showErrorMessage(error);
      }
    );
  }

  editUserProfile() {
    // toggle the class to apply border
    var myInput = document.getElementById("username");
    myInput.classList.toggle('userNameblockBorder');
    //end
    this.userName = true;
  }

  editUserDob() {
    this.dob = true;
  }

  updateUserProfile() {
    this.spinner.show();
    let selectedDate = new Date(this.selectedDate);
    var newDate = (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate() +   "/" + selectedDate.getFullYear();

    let toTimestamp = (new Date(newDate).getTime());
    console.log(toTimestamp, 'toTimeStamp');
    let updatedUserDetails = {
      fullName: this.updatedUserDetails.fullName,
      address: this.updatedUserDetails.address,
      dob: toTimestamp,
    }
    this.authService.updateUserProfile(updatedUserDetails).subscribe((res) => {
      this.spinner.hide();
      this.authService.changeName(this.updatedUserDetails.fullName);
      this.userProfile();
    })
    this.userName = false;
    this.address = false;
    this.dob = false;
  }

  editUserAddress() {
    this.address = true;
  }

  parseDate(dateString: string): Date {
    if (dateString) {
      return new Date(dateString);
    }
    return null;
  }

  // updateUserAddress() {
  //   this.spinner.show();
  //   let updatedUserDetails = {
  //     address: this.updatedUserDetails.address
  //   }
  //   this.authService.updateUserProfile(updatedUserDetails)
  //     .subscribe(
  //       (success: any) => {
  //         console.log(success);
  //         this.toastr.success(success.message);
  //         this.spinner.hide();
  //         this.authService.changeName(this.updatedUserDetails.fullName);
  //         this.userProfile();
  //       }, (error: HttpErrorResponse) => {
  //         console.log(error);
  //         this.messageService.showErrorMessage(error);
  //         this.spinner.hide();
  //       })
  //   this.userName = false;
  // }
  onDateChange(newDate: Date) {
    console.log(newDate);
  }
  // getUpdatedDate() {
  //   let selectedDate = new Date(this.selectedDate);
  //   var newDate = (selectedDate.getMonth() + 1) + "/" + selectedDate.getDate() +   "/" + selectedDate.getFullYear();

  //   let toTimestamp = (new Date(newDate).getTime());
  //   console.log(toTimestamp, 'toTimeStamp');
  //   let updatedUserDetails = {
  //     dob: toTimestamp,
  //     fullName: this.updatedUserDetails.fullName,
  //     address: this.updatedUserDetails.address,
  //   }
  //   this.authService.updateUserProfile(updatedUserDetails)
  //     .subscribe(
  //     (success: any) => {
  //       this.spinner.hide();
  //       this.toastr.success(success.message);
  //       this.authService.changeName(this.updatedUserDetails.fullName);
  //       this.userProfile();
  //           }, (error: HttpErrorResponse) => {
  //             console.log(error);
  //             this.messageService.showErrorMessage(error);
  //             this.spinner.hide();
  //     })
  //     this.userName = false;
  //     this.address = false;
  //     this.dob = false;
  //   }

}


