import { Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router} from '@angular/router';
import { UserProfileModel } from 'src/app/right-sidebar/profile/profile.model';
// import {ChangeNameService} from 'src/app/services/changeName.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userData: UserProfileModel;
  fullName: string;

  constructor(private authService: AuthService,
    // private changeName: ChangeNameService,
    private toastr: ToastrService,
    private router: Router,
    private messageService : MessageService,) {
      this.userData = new UserProfileModel();
     }
    
  ngOnInit() { 
    this.authService.currentUser.subscribe(message => this.userData.fullName = message)
    this.userProfile();
  }
  
  onLogout() {
    console.log("log out api call");
    this.authService.logout()
    .subscribe(
      (success: any) => {
        window.localStorage.clear();
        this.router.navigateByUrl('logIn');
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
      })
  }

  userProfile() {
    this.authService.userProfile().subscribe(
      (success: any) => {
        // this.changeName.currentUserName.subscribe(userName => {
        //   this.userData = userName;
        //   console.log (this.userData, 'updated Name');
        // });
        this.userData = success.responseObject;
        console.log(this.userData, 'get')
        console.log(success);
        
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        // this.messageService.showErrorMessage(error);
      }
    );


  }
 

}
