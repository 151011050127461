<!-- <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head> -->
<div class="container-fluid">

    <div class="logo">
        <div class="logo-img">
            <img src="assets/icons/logo.png" alt="">
        </div>
    </div>
    <div class="logo-1">
        <div class="logo-img-1">
            <img src="assets/icons/Asset 1.png" alt="">
        </div>
    </div>
    <div class="logo-2">
        <div class="logo-img-2">
            <img src="assets/icons/Group 381@2x.png" alt="">
        </div>
    </div>
    <div class="border-form">
        <div class="heading-1"><b>Forgot password?</b></div>
        <div class="description-1">Please enter your registered email address we will send</div>
        <div class="description-2">you the reset password link.</div>
        <div class="box">
            <!-- <form [formGroup]="emailVerifyform" (ngSubmit)="onSubmit(email)"> -->
            <form action="" [formGroup]="RequestResetForm" (ngSubmit)="RequestResetUser(RequestResetForm)">
                <div class="input-container-1">
                    <label>Enter email</label>
                    <br>
                    <input type="text" formControlName="email" [(ngModel)]="forgotData.email"autocomplete="off" name="email" placeholder="Lee lee"
                        required>
                    <span *ngIf="!RequestResetForm.get('email').valid && !IsvalidForm" class="text-danger color-primary">Please enter a
                        valid email!</span>
                </div>

                <div mt-4>
                    <button class="btn-2 mt-4">Submit</button>
                </div>
            </form>
        </div>
      

    </div>

</div>