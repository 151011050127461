import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { PaymentMethodComponent } from './right-sidebar/payment-method/payment-method.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Routes, RouterModule } from '@angular/router';
import { ReceiptsComponent } from './right-sidebar/receipts/receipts.component';
import { TradingComponent } from './right-sidebar/trading/trading.component';
import { ProfileComponent } from './right-sidebar/profile/profile.component';
import { SettingsComponent } from './right-sidebar/settings/settings.component';
import { LogoutComponent } from './right-sidebar/logout/logout.component';
import { logInComponent } from './common/logIn/logIn.component';
import { SignupComponent } from './common/signup/signup.component';
import { AuthService } from './services/auth.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResetpasswordComponent } from './common/resetpassword/resetpassword.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { KycComponent } from './common/kyc/kyc.component';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { kycRegistrationComponent} from './common/kycRegistration/kycRegistration.component';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { ChangePasswordComponent } from './right-sidebar/change-password/change-password.component';
import { ForgotPasswordComponent } from './common/forgot-password/forgot-password.component';
import { MailVerifyComponent } from './common/mail-verify/mail-verify.component';
import { MessageService } from './services/message.service';
import { AuthenticatedHttpService } from './api-interceptor';
import { AuthGuardService } from './services/authGuard.service';
import { NgxSpinnerModule } from "ngx-spinner";
import "@angular/compiler";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AnQrcodeModule } from 'an-qrcode';
import { NgxStripeModule } from 'ngx-stripe';




const appRoutes: Routes = [
  { path: "", redirectTo: 'signup', pathMatch: 'full' },
  { path: "signup", component: SignupComponent },
  { path:"logIn",component:logInComponent},
  { path: "resetpassword", component: ResetpasswordComponent },
  { path: "dashboard", component: DashboardComponent,canActivate: [ AuthGuardService ] },
  { path: "payment-method", component: PaymentMethodComponent,canActivate: [ AuthGuardService ] },
  { path: "receipts", component: ReceiptsComponent,canActivate: [ AuthGuardService ] },
  { path: "trading", component: TradingComponent,canActivate: [ AuthGuardService ] },
  { path: "profile", component: ProfileComponent,canActivate: [ AuthGuardService ] },
  { path: "settings", component: SettingsComponent,canActivate: [ AuthGuardService ] },
  { path: "logout", component: LogoutComponent },
  {path:"kyc", component:KycComponent,canActivate: [ AuthGuardService ]},
  {path:"kycRegistration", component:kycRegistrationComponent,canActivate: [ AuthGuardService ]},
  {path:"change-pswd", component:ChangePasswordComponent,canActivate: [ AuthGuardService ]},
  {path:"forgot-pswd", component:ForgotPasswordComponent},
  {path:"mail-verify", component:MailVerifyComponent},
  

]
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    PaymentMethodComponent,
    ReceiptsComponent,
    TradingComponent,
    ProfileComponent,
    SettingsComponent,
    LogoutComponent,
    logInComponent,
    SignupComponent,
    KycComponent,
    kycRegistrationComponent,
    ResetpasswordComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    MailVerifyComponent,
    
    
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ClipboardModule,
    // HttpClientModule,
    FormsModule,
    AnQrcodeModule,
    BrowserAnimationsModule,
    ModalModule,
    CommonModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot('pk_test_3fvlarcvAPkv2Z7INm8xHrRY00jOpUI8A2'),
    // AccordionModule.forRoot(),
    ToastrModule.forRoot(),
    // AlertModule.forRoot(),
    // ButtonsModule.forRoot(),
    // CarouselModule.forRoot(),
    // CollapseModule.forRoot(),
    // BsDropdownModule.forRoot(),
    // PaginationModule.forRoot(),
    // PopoverModule.forRoot(),
    // ProgressbarModule.forRoot(),
    // RatingModule.forRoot(),
    // SortableModule.forRoot(),
    // TabsModule.forRoot(),
    // TimepickerModule.forRoot(),
    // TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    RouterModule.forRoot(appRoutes),

    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticatedHttpService,
      multi: true,
    },
    AuthenticatedHttpService,
    AuthService, HttpClient, MessageService,AuthGuardService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
