
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<app-header></app-header>
<app-sidebar></app-sidebar>
<!-- Table-1 -->
<div class="background">
<div class="col-md-12 pl-md-0">
 <div class="box-1">
<div class="h-1">Portfolio Value</div>
<div class="z-4"><a  class="z4-4">1D</a></div>
  <div class="h-5"><a  class="z5-5">1W</a></div>
  <div class="h-6"><a  class="z6-6">1M</a></div>
  <div class="h-7"><a  class="z7-7">1Y</a></div>
  <div class="h-8"><a  class="z8-8">MAX</a></div>
 </div>

 <div class="box-2"  *ngIf="accountBalance">
  <div class="row">
    <div class="col-md-12">
      <p class="my-balance-heading" >My Balances</p>
        <table class="balance-table">
          <tr class="heading-row p-3">
            <th class="w20 pl display">Coins</th>
            <th class="w20"></th>
            <th class="w20 pl">Value</th>
            <th  class="w20 pl display">Deposit</th>
            <th class="w20 pl display">Withdraw</th>
          </tr>
          <tr *ngFor="let balance of balanceDetail">
            <td class="w20 display"></td>
            <td class="w20">
              <!-- <label>{{balance.assetCode}}</label> -->
              <p class="code">{{balance.assetCode}}</p>
            </td>
            <td class="w20"><p class="code">{{balance.balance | number : '1.2-2'}}</p></td>
            <td class="w20 display">
                <button routerLink="/settings" class="btn btn-light-blue" class="deposit-button">Deposit</button>
            </td>
            <td class="w20 display">
                <button class="btnall_1">Withdraw</button>
            </td>
          </tr>
      </table>
    
  </div>
</div>
</div>

<div class="box-2"  *ngIf="!accountBalance">
  <div class="row">
    <div class="col-md-12">
      <p class="my-balance-heading" >My Balances</p><br>
      <br>
      <p class="my-balance">You need to add funds in your Stellar Account</p>
  </div>
</div>
</div>
 <div class="t-1"> 
  <div class="h-1">Outstanding Contracts & Funding Levels</div>
  <div class="bt-2">
    <select class="form-control btn-light-blue" class="export">
      <option>Export to CSV</option>
    </select>
  </div>
  <div class="h-4"><a  class="h4-4">1D</a></div>
  <div class="h-5"><a  class="h5-5">1W</a></div>
  <div class="h-6"><a  class="h6-6">1M</a></div>
  <div class="h-7"><a  class="h7-7">1Y</a></div>
  <div class="h-8"><a  class="h8-8">MAX</a></div>
  
    
      <div class="t-2">
    
    <div class="d-1">Date & Time</div>
   
    <div class="d-2">Currency</div>
    <div class="z-3">Type</div>
    <div class="z-6">Total</div>
    <div class="z-4">Price</div>
    <div class="d-5">Amount</div>
    <div class="d-7">Status</div>
  </div>
  
  <!-- <div class="a-1">2020-01-13 01:23:54</div>
  <div class="a-2">2020-01-13 01:23:54</div>
  <div class="a-3">2020-01-13 01:23:54</div>
  <div class="a-4">2020-01-13 01:23:54</div>
  <div class="a-5">2020-01-13 01:23:54</div> -->

  <!-- <div class="c-1">BTC</div>
  <div class="c-2">NVOV</div>
  <div class="c-3">LTH</div>
  <div class="c-4">BTC</div>
  <div class="c-5">LTH</div>
  <div class="x-1">$ 56.20</div>
  <div class="x-2">$ 21.20</div>
  <div class="x-3">$ 06.44</div>
  <div class="x-4">$ 21.20</div>
  <div class="x-5">$ 06.44</div> -->
  <!-- <div class="btn1-1">
    <button class="btn btn-light-green w-100" class="btnall-1" data-toggle="modal"
      data-target="#exampleModalCenter">BUY</button>
  </div>
  <div class="btn2-2">
    <button class="btn btn-light-green w-100" class="btnall-2" data-toggle="modal"
      data-target="#exampleModalCenter">SELL</button>
  </div>
  <div class="btn3-3">
    <button class="btn btn-light-green w-100" class="btnall-3" data-toggle="modal"
      data-target="#exampleModalCenter">SELL</button>
  </div>
  <div class="btn4-4">
    <button class="btn btn-light-green w-100" class="btnall-4" data-toggle="modal"
      data-target="#exampleModalCenter">BUY</button>
  </div>
  <div class="btn5-5">
    <button class="btn btn-light-green w-100" class="btnall-5" data-toggle="modal"
      data-target="#exampleModalCenter">SELL</button>
  </div> -->
  
  <!-- <div class="f-1">$ 656. 14</div>
  <div class="f-2">$ 653. 41</div>
  <div class="f-3">$63.00</div>
  <div class="f-4">$ 636. 41</div>
  <div class="f-5">$63.00</div>
  <div class="g-1">0.014</div>
  <div class="g-2">0.00056</div>
  <div class="g-3">0.014</div>
  <div class="g-4">0.00056</div>
  <div class="g-5">0.014</div>
  <div class="s-1">Completed</div>
  <div class="s-2">Completed</div>
  <div class="s-3">Rejected</div>
  <div class="s-4">Completed</div>
  <div class="s-5">Completed</div> -->

  <div class="d-8">No data to show</div>


</div> 

  
<!-- Table-2 -->
 <!-- <div class="con">  -->
  
  <div class="b-1">
    <div class="h-1">Transaction Summary</div>
    <div class="h-2">Deposit</div>
    <div class="h-3">Withdraw</div>
    <div class="bt-1 ">
      <button class="btn btn-light-blue w-100" class="btnall">All</button>
    </div>
    <div class="bt-2">
      <select class="form-control btn-light-blue" class="export">
        <option>Export to CSV</option>
      </select>
    </div>
    <div class="h-4"><a  class="h4-4">1D</a></div>
    <div class="h-5"><a  class="h5-5">1W</a></div>
    <div class="h-6"><a  class="h6-6">1M</a></div>
    <div class="h-7"><a  class="h7-7">1Y</a></div>
    <div class="h-8"><a  class="h8-8">MAX</a></div>
    <div class="t-2">
      <div id="table-wrapper">
        <div id="table-scroll">
          <table>
            <thead>
              <tr>
                <th class="w201 pl"><label class="text">Id</label></th>
                
                <th class="w20 pl"><label class="text">Date & Time</label></th>
                <th class="w20 pl"><label class="text">Buying Currency</label></th>
                <th class="w20 pl"><label class="text">Buying Amount</label></th>
              
                <th class="w20 pl"><label class="text">Payment Amount</label></th>
          
                <th class="w20 pl"><label class="text">Payment Currency</label></th>
                <th class="w20 pl"><label class="text">Status </label></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let transaction of transactionDetail">
                <td class="w20">
                  <label class="f-label">{{transaction.id}}</label>
                </td>
                <td class="w20">
                  <label class="f-label">{{transaction.createdOn | date:'medium'}}</label>
                </td>
                <td class="w20">
                  <label class="f-label">{{transaction.buyingCurrency}}</label>
                </td>
                <td class="w20">
                  <label class="f-label">{{transaction.buyingAmount}}</label>
                </td>
              
                <td class="w20">
                  <label class="f-label">{{transaction.paymentCurrency}}</label>
                </td>
              
                <td class="w20">
                  <label class="f-label">{{transaction.paymentAmount}}</label>
                </td>
                <td class="w20">
                  <label class="f-label">{{transaction.transactionStatus}}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- <div class="d-8">No data to show</div> -->
  </div>
</div>
<!-- </div> -->
</div>

