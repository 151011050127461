import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { resetPasswordDetails } from './resetpassword.modal';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs/internal/Observable';

declare var $: any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
  ResponseResetForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  resetToken: any;
  CurrentState: any;
  IsResetFormValid = true;
  resetPasswordData: resetPasswordDetails = new resetPasswordDetails();


  resetData: {
    password: string
    confirmPassword: string,
  }
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private messageService : MessageService,
    private fb: FormBuilder) {
    
    this.CurrentState = 'Wait';
  }

  ngOnInit() {
    
    this.route.queryParams.subscribe(queryParams => {
      var token = queryParams['token'];
      console.log(token, 'token ');
      localStorage.setItem('token', token);
      // Call your Backend API with the token after this
    });
    this.Init();
    this.ResponseResetForm = this.fb.group({
      newpassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

  Init() {
    this.ResponseResetForm = this.fb.group(
      {
        resettoken: [this.resetToken],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
      }
    );
  }

  ResetPassword(userData) {
    this.spinner.show();
    this.authService.resetPassword(userData)
    return this.authService.resetPassword(userData)
    .subscribe(
      (success: any) => {
        this.spinner.hide()
        this.router.navigateByUrl('logIn');
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
        this.spinner.hide();
      })
  }


}


