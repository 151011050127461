<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="col-md-7">
  <div class="container-fluid p-3 payment-method pr-4 height-100vh pt-4">
  
    <div class="b-1">
      <div class="h-1">Incoming transaction summaries</div>
      <div class="h-2">Sent</div>
      <div class="h-3">Requested</div>
      <div class="bt-1 ">
          <button class="btn btn-light-blue w-100" class="btnall">All</button>
        </div>
        <div class="bt-2">
          <select class="form-control btn-light-blue" class="export">
            <option>Export to CSV</option>
          </select>
        </div>
    <div class="h-4"><a class="h4-4">1D</a></div>
    <div class="h-5"><a class="h5-5">1W</a></div>
    <div class="h-6"><a class="h6-6">1M</a></div>
    <div class="h-7"><a class="h7-7">1Y</a></div>
    <div class="h-8"><a class="h8-8">MAX</a></div>
    
    <div class="t-2">
      <div class="d-1">Date & Time</div>
      <div class="d-2">Currency</div>
      <div class="d-3">Type</div>
      <div class="d-4">Price</div>
      <div class="d-5">Amount</div>
      <div class="d-6">Total</div>
      <div class="d-7">Status</div>
  </div>
  <div class="d-8">No data to show</div>
  </div>
  
  </div>
  
  </div>
  