export class Forgotpassword {
 
    oldpassword: string;
    newpassword: string;
    confirmpassword:string;
  }

  export class ForgotPasswordRequestModel{
    email: string;
    constructor(){
      this.email = null;
    }
  }
