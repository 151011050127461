<div class="container-fluid">
  <div class="logo">
    <div class="logo-img">
      <img src="assets/icons/logo.png" alt="">
    </div>
  </div>
  <div class="logo-1">
    <div class="logo-img-1">
      <img src="assets/icons/Asset 1.png" alt="">
    </div>
  </div>
  <div class="logo-2">
    <div class="logo-img-2">
      <img src="assets/icons/Group 381@2x.png" alt="">
    </div>
  </div>
  <div class="border-form">

    <div class="heading-1"><b>Reset Password</b></div>
    <div class="box">
      <form #resetForm="ngForm">
        <div class="input-container-2 form-group">
          <input type="password" [(ngModel)]="resetPasswordData.newPassword" name="newPassword" #newPassword="ngModel"
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$^&*-+_;./>:}/|]).{8,64}$" required />
          <label>New Password</label>
          <div *ngIf="newPassword.invalid && newPassword.touched">
            <div *ngIf="newPassword.errors.pattern" class="text-danger color-primary">
              Password should be 8-64 character long and it should contain atleast one
              uppercase, one lowercase , one number and one special character
            </div>
            <div *ngIf="newPassword.errors.required" class="text-danger color-primary">
              Password is required
            </div>
          </div>
        </div>

        <div class="input-container-4 form-group">
          <input type="password" [(ngModel)]="resetPasswordData.confirmPassword" name="confirmPassword"
            #confirmPassword="ngModel" autocomplete="off" required />
          <label>Confirm New Password</label>
          <div *ngIf="confirmPassword.touched">
            <div *ngIf="confirmPassword.errors?.required" class="text-danger color-primary">
              Password is required
            </div>
            <div
              *ngIf="confirmPassword.touched && (resetPasswordData.newPassword != resetPasswordData.confirmPassword)">
              <div class="text-danger color-primary">Passwords do not match</div>
            </div>
          </div>
        </div>


        <div class="col-md-6 ">
          <button class="btn-1" type="submit" [disabled]="!resetForm.valid"
            (click)="ResetPassword(resetPasswordData)">Submit</button>
        </div>
      </form>
    </div>


  </div>