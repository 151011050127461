import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ForgotPasswordRequestModel } from '../models/forgotpassword';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  RequestResetForm: FormGroup;
  forbiddenEmails: any;
  // errorMessage: string;
  successMessage: string;
  IsvalidForm = true;
  forgotData = new ForgotPasswordRequestModel();

  constructor(  private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.RequestResetForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email], this.forbiddenEmails),
    });
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

  RequestResetUser(form) {
    this.spinner.show();
    console.log(form)
    if (form.valid) {
      this.IsvalidForm = true;
      this.authService.forgotPassword(this.RequestResetForm.value).subscribe(
      
        (success: any) => {
          this.spinner.hide();
          console.log(this.RequestResetForm.value);
          this.RequestResetForm.reset();
         this.toastr.success(success.message);
          setTimeout(() => {
            this.successMessage = null;
          }, 3000);
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.spinner.hide();
          this.messageService.showErrorMessage(error);
        }
      );
    } else {
      this.IsvalidForm = false;
    }
  }
}


