<app-header></app-header>
<app-sidebar></app-sidebar>

<div class="container-fluid pt-md-3 trading pb-md-3 height-100vh pt-md-4">
    <div class="row">
      <div class="col-md-6 buy-envoy pos-center">
        <div class="wrapper-box">
          <div class="wrapper-box-content">
              <div class="text-heading-1 border-bottom-dark">Deposit</div>
              <!-- <button class="btn btn-dark-blue w-70 mt-3 mb-3 height-40">Deposit</button> -->
              <!-- <div class="label-text pt-md-3">Currency</div>
              <div class="form-group">
                  <select formControlName="expMonth" class="form-control f-size"  [(ngModel)]="currency" name="currency">
                    <option value=""> XLM</option>
                    <option value="1">INR</option>
                    <option value="1">SAD</option>
                  </select>
                </div> -->
                <div class="label-text mt-5">Currency</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="currency"
                  type="text" name="currency"></div>
              <div class="label-text">Amount</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="amount"
                  type="text" name="amount"></div>
              <div class="label-text">Email</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="email"
                  type="text" name="email"></div>
              <div class="label-text">Description</div>
              <div class="form-group"><input class="form-control own-form-control" 
                  type="text" name="description"></div>
                  <button class="btn btn-light-blue w-70 mt-5 mb-3" (click)="deposit(amount, currency)" >Submit</button>
                  <!-- <button (click)="pay(20)" class="btn btn-primary btn-block">Submit</button> -->
          </div>
        </div>
      </div>
  
     
    </div>
  </div>






