import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { BuyEnvoyModel, BuyRequestModel, SellRequestModel, SellEnvoyModel, TradeTransactionHistoryRequest, TradeTransactionHistory } from './trading.model';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-trading',
  templateUrl: './trading.component.html',
  styleUrls: ['./trading.component.scss']
})
export class TradingComponent implements OnInit {
  buyEnvoyDetail: BuyEnvoyModel;
  buyEnvoyRequest: BuyRequestModel;
  accountId: any;
  sellEnvoyDetail: SellEnvoyModel;
  sellEnvoyRequest: SellRequestModel;
  tradeRequest: TradeTransactionHistoryRequest;
  tradeDetail: Array<TradeTransactionHistory>;

  constructor(private spinner: NgxSpinnerService,
    private router :Router,
    private toastr: ToastrService,
    private messageService : MessageService,
    private authService: AuthService,) {
    this.buyEnvoyDetail = new BuyEnvoyModel();
    this.buyEnvoyRequest = new BuyRequestModel();
    this.sellEnvoyDetail = new SellEnvoyModel();
    this.sellEnvoyRequest = new SellRequestModel();
    this.tradeRequest = new TradeTransactionHistoryRequest();
    this.tradeDetail = new Array<TradeTransactionHistory>();
   }

  ngOnInit() {
    this.spinner.show();
    this.tradeHistory();
    var accountId = localStorage.getItem("accountId");
    console.log('id', accountId);
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

  buyEnvoy(buyEnvoyRequest, accountId) {
    this.spinner.show();
    this.authService.buyEnvoy(buyEnvoyRequest,accountId)
    .subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log(success);
        this.tradeHistory();
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
        this.spinner.hide();
      })
  }

  sellEnvoy(sellEnvoyRequest, accountId) {
    this.spinner.show();
    this.authService.sellEnvoy(sellEnvoyRequest,accountId)
    .subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log(success);
        this.tradeHistory();
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
        this.spinner.hide();
      })
  }


  tradeHistory() {
    this.spinner.show();
    this.authService.tradeHistory(this.tradeRequest).subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log("transaction summary api call");
        this.tradeDetail = success.responseObject;
        console.log(this.tradeDetail, 'response')
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        // this.messageService.showErrorMessage(error);
      }
    );
  }

}
