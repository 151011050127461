import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-logIn',
  templateUrl: './logIn.component.html',
  styleUrls: ['./logIn.component.scss']
})
export class logInComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  loginData = new User();
  data:any;
  accountId: any;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router :Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private messageService : MessageService,
  ) {}
  emailregex = /^[a-z]+[a-z0-9._+a-z]+@[a-z0-9]+\.[a-z.]{2,}$/
  passwordregex= /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  ngOnInit() {
    this.loginData = new User();
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern(this.emailregex)]],
      password: ['', [Validators.required, Validators.pattern(this.passwordregex)]],
      
    });
    // this.spinner.show();
 
    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 2000);
  }
  onSubmit(User) {
    this.spinner.show();
    this.authService.loginuser(User)
    .subscribe(
      (success: any) => {
        this.router.navigate(['/dashboard'])
        this.toastr.success(success.message);
        this.data = success.responseObject.authToken;
        this.accountId = success.responseObject.userDetails.accountId;
        this.spinner.hide();
        localStorage.setItem('authToken', this.data);
        localStorage.setItem('accountId', this.accountId);
        console.log('id', this.accountId);
        console.log("hiii aman this is my token",this.data);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
        this.router.navigate(['/logIn'])
        this.spinner.hide();
      })

    // if (this.form.valid) {
    //   this.authService.login(this.form.value);
    // }
  }
  // onSignin() {
  //   this.authService.user();
  // }
}
