import { Injectable } from '@angular/core'
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthService } from './auth.service'
// import { LocalStorageService } from 'ngx-webstorage'

@Injectable()
export class AuthGuardService implements CanActivate {
localStorage: any

constructor(private readonly authService: AuthService, private readonly router: Router) { }
canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
const token = localStorage.getItem('authToken')
console.log(token)
if (token) {
return true
}
this.router.navigate(['/logIn'])
return false
}
}