<app-header></app-header>
<app-sidebar></app-sidebar>

<div class="container-fluid p-3 payment-method pr-4 height-100vh pt-4">
  <div class="row">
    <div class="col-md-7 payment-divison">
      <div class="wrapper-box">
        <div class="text-heading-1 border-bottom-dark">Payments</div>
        <div class="wrapper-section">
          <div class="row ">
            <div class="col-md-6 pt-2">
              <button class="btn btn-dark-blue w-100 height-40">Strict Send Payment</button>
              <div class="label-text pt-md-3">Address/ID</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sendPayment.toAddress"
                  type="text" name="selladdress"></div>
              <div class="label-text">Amount</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sendPayment.amount"
                  type="number" name="sellamount"></div>
              <div class="label-text">Source</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sendPayment.source"
                  type="text" name="sellsource"></div>
              <div class="label-text">Sending Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sendPayment.senderAssetCode"
                  type="text" name="sellasset"></div>
              <!-- <div class="label-text">Token</div>
              <div class="form-group"><input class="form-control own-form-control"
                  [(ngModel)]="sendPayment.isNativeToken" type="text" name="note"></div> -->
              
              <div class="label-text">Destination Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sendPayment.destinationAssetCode"
                  type="text" name="buyasset"></div>
              <button class="btn btn-light-blue w-100  pt-md-3 mt-2"
                (click)="sendEnvoy(sendPayment)">Send</button>
            </div>
            <div class="col-md-6 pt-md-2 pl-md-0">
              <button class="btn btn-dark-blue w-100 receive-nvoy height-40">Strict Receive Payment</button>

              <div class="label-text pt-md-3">Address/ID</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="receivePayment.toAddress"
                  type="text" name="buyaddress"></div>
              <div class="label-text">Amount</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="receivePayment.amount"
                  type="number" name="buyamount"></div>
              <div class="label-text">Source</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="receivePayment.source"
                  type="text" name="note"></div>
              <div class="label-text">Sending Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="receivePayment.senderAssetCode"
                  type="text" name="buysellasset"></div>
              <!-- <div class="label-text">Token</div>
              <div class="form-group"><input class="form-control own-form-control"
                  [(ngModel)]="sendPayment.isNativeToken" type="text" name="note"></div> -->
              
              <div class="label-text">Destination Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="receivePayment.destinationAssetCode"
                  type="text" name="buybuyasset"></div>

              <!-- <div class="label-text pt-md-3">Address/ID</div>
              <div class="form-group row">
                <div class="col-md-5">
                  <label class="wallet-address" *ngIf="!balance">No Data to show</label>
                  <input *ngIf="balance" class="form-control wallet-add" disabled [value]="address" type="text">
                  <button *ngIf="balance" class="copy-btn btn-light-blue" [cdkCopyToClipboard]="address">Copy</button>
                </div>
                <div class="col-md-7 " data-toggle="modal" data-target="#qrCodeModal">
                  <qrcode [level]="1" [qrvalue]="address" [id]="'qr-code'"></qrcode>
                </div>

              </div>
              <div class="label-text">Balance</div>
              <div *ngIf="!balance">
                <label class="no-data" *ngIf="!balance">No data to show</label>
              </div>

              <table class="balance-table" *ngIf="balance">
                <tr class="heading-row p-3">
                  
                </tr>
                <tr *ngFor="let balance of balanceDetail">
                  <td class="w20"><label>{{balance.assetCode}}</label>
                  </td>
                  <td class="w20"><label class="f-label">${{balance.balance | number : '1.2-2'}}</label></td>
                </tr>
              </table> -->
              <button class="btn btn-light-blue w-100 pt-md-3 rcv-btn" (click)="receiveEnvoy(receivePayment)">Receive</button>
            </div>
          </div>


        </div>
      </div>


    </div>
    <div class="col-md-5 pl-md-0 conversion-divison">
      <div class="wrapper-box">
        <div class="text-heading-1 border-bottom-dark">Convert Funds</div>
        <div class="wrapper-section">
          <div class="label-heading-1 text-center pt-md-3 pb-3">Rate 1 {{marketCurrency}} = {{convertedFund}} {{pairedCurrency}}</div>
          <form [formGroup]="convertFund" >
          <div class="row align-items-center">
            <div class="col-3">
              <div class="label-heading-1">Convert from</div>
            </div>
            <div class="col pr-0 pl-0">
              <!-- <div><input type="text" class="form-control own-form-control"></div> -->
            </div>
            <div class="col">
              <div>
                <select (change)="selectChangeHandler($event)" class="form-control btn-light-blue"  formControlName="currency" id="currency">
                   <!-- <option [(ngModel)]='selectedValue'>--select--</option> -->
                  <option *ngFor="let currency of currencyList; let i = index" >{{currencyList[i]}} </option>
                  <!-- <option  >BTC (BTC Coin)</option> -->
                </select>
              </div>
            </div>
          </div>
          <div class="own-transfer pt-4 pb-4">
            <a href="">
              <svg width="47" height="19.886" viewBox="0 0 47 19.886">
                <g transform="translate(-6 -39.999)">
                  <path class="a"
                    d="M52.614,51.913l-9.038-6.327a.9.9,0,0,0-1.422.74v2.712H15.942a.9.9,0,0,0-.9.9v1.879L8.48,47.231l6.558-4.591v1.879a.9.9,0,0,0,.9.9H29.5V43.615H16.846V40.9a.9.9,0,0,0-1.422-.741L6.386,46.489a.9.9,0,0,0,0,1.481L15.424,54.3a.9.9,0,0,0,1.422-.74V50.846H43.058a.9.9,0,0,0,.9-.9V48.063l6.558,4.591-6.558,4.591V55.365a.9.9,0,0,0-.9-.9H29.5v1.808H42.154v2.712a.9.9,0,0,0,1.422.74l9.038-6.327a.9.9,0,0,0,0-1.481Z"
                    transform="translate(0)" />
                </g>
              </svg>
            </a>
          </div>
          <div class="row align-items-center">
            <div class="col-3">
              <div class="label-heading-1">Convert To</div>
            </div>
            <div class="col pr-0 pl-0">
              <!-- <div><input type="text" class="form-control own-form-control"></div> -->
            </div>
            <div class="col">
              <div>
                <!-- <select class="form-control btn-light-blue">
                  <option>USD (US Dollar)</option>
                </select> -->
                <select (change)="selectChangeHandlerTo($event)" class="form-control btn-light-blue"  formControlName="currency" id="currency">
                  <!-- <option>{{currencyList[1]}}</option> -->
                  <option *ngFor="let currency of currencyList; let i = index">{{currencyList[i]}} </option>
                  <!-- <option  >BTC (BTC Coin)</option> -->
                </select>
                <!-- <p><span>You selected: </span><b>{{selectedLevel}}</b></p> -->
              </div>
            </div>
          </div>
        </form>
          <!-- <div class="label-heading-1 text-center pt-4 pb-4">Current Fee = 0.25 USD</div> -->
          <div class="col-md-12 mt-3">
            <button class="btn btn-light-blue position w-100 mt-13 mar-t-10"
            (click)= "convertFunds(marketCurrency, pairedCurrency)">Submit</button>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<!-- table -->
<div>
  <div class="t-1">
    <div class="h-1">Transaction Summary</div>
    <div class="h-2" (click)="senttransactionSummary()">Sent</div>
    <div class="h-3" (click)="receivetransactionSummary()">Received</div>
    <div class="b-1 ">
      <button class="btn btn-light-blue w-100" class="btnall">All</button>
    </div>
    <div class="b-2">
      <select class="form-control btn-light-blue" class="col-md-7 export">
        <option>Export to CSV</option>
      </select>
    </div>
    <div class="h-4"><a class="h4-4" (click)="onDurationClick('1')">1D</a></div>
    <div class="h-5"><a class="h5-5" (click)="onDurationClick('7')">1W</a></div>
    <div class="h-6"><a (click)="onDurationClick('30')" class="h6-6">1M</a></div>
    <div class="h-7"><a (click)="onDurationClick('365')" class="h7-7">1Y</a></div>
    

    <div class="t-2">
      <div id="table-wrapper">
        <div id="table-scroll">
          <table>
            <thead>
              <tr>
                <th class="w201 pl"><label class="text">Date & Time</label></th>
                
                <th class="w20 pl"><label class="text">Sending Asset</label></th>
                <th class="w20 pl"><label class="text">Destination Asset</label></th>
                <th class="w20 pl"><label class="text">Type</label></th>
              
                <th class="w20 pl"><label class="text">Amount</label></th>
          
                <th class="w20 pl"><label class="text">Status</label></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let transaction of transactionDetail">
                <td class="w20">
                  <label class="f-label">{{transaction.createdOn | date:'medium'}}</label>
                </td>
                <td class="w20">
                  <label class="f-label">{{transaction.senderAssetCode}}</label>
                </td>
                <td class="w20">
                  <label class="f-label">{{transaction.destinationAssetCode}}</label>
                </td>
                <td class="w20">
                  <label class="f-label">{{transaction.paymentType}}</label>
                </td>
              
                <td class="w20">
                  <label class="f-label">{{transaction.amount}}</label>
                </td>
              
                <td class="w20">
                  <label class="f-label">{{transaction.transactionStatus}}</label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div> 




<!-- Modal -->
<div class="modal fade" id="qrCodeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content bg-color">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal ti</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <qrcode [level]="1" [qrvalue]="address" [id]="'qr-code'"></qrcode>

      </div>
      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-green" data-dismiss="modal">Yes</button>
          <button type="button" class="btn btn-red">Cancel</button>
        </div> -->
    </div>
  </div>
</div>