import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { VerifyMail } from '../models/mail-verify';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-mail-verify',
  templateUrl: './mail-verify.component.html',
  styleUrls: ['./mail-verify.component.scss']
})
export class MailVerifyComponent implements OnInit {

  mailverified: VerifyMail;
  mailVerify: boolean;

  constructor( private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService) { 
      this.mailverified = new VerifyMail();
    }

  ngOnInit() {
    // this.spinner.show();
    this.mailVerify = true;
    this.route.queryParams.subscribe(queryParams => {
      var token = queryParams['token'];
      console.log(token, 'token ');
      localStorage.setItem('token',token);
      // Call your Backend API with the token after this
    });
  }

  

  verifyMail(){
    this.spinner.show();
    // this.mailVerify = false;
    this.authService.mailVerify().subscribe(
      (success: any) => {
        this.spinner.hide();
        this.mailVerify = false;
        this.router.navigate(['/logIn'])
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        console.log(error);
        this.messageService.showErrorMessage(error);
      })
      
  }
}
