import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { KycDetails } from '../models/kycDetails';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {
  kycform: FormGroup;
  submitted: boolean;
  KycData = new KycDetails();
  token: string;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router :Router
   
  ) {

  }
  emailregex = /^[a-z]+[a-z0-9._+a-z]+@[a-z0-9]+\.[a-z.]{2,}$/
  
  ngOnInit() {
    this.token = localStorage.getItem('token');
    this.KycData = new KycDetails();
    this.kycform = this.fb.group({
    email: ['', [Validators.required, Validators.email,Validators.pattern(this.emailregex)]],
     lastName: ['', Validators.required ],
     firstName: ['',Validators.required],
     countryCode:['',Validators.required],
    
    });
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }
  onSubmit(KycDetails) {
    this.spinner.show();
    this.authService.kycuser(KycDetails).subscribe((res)=>{
      console.log(JSON.stringify(res))
      console.log('dynamo' + localStorage.getItem('token'))
      if(res){
        this.spinner.hide();
        console.log('link' + window.location.href);
        window.location.href= 'https://www.kychub.com/fas/userhome?authtoken=token'
        // window.location.href='https://www.kychub.com/fas/{language_isocode}/startkyc?token=eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJhcmcxXCI6XCJheXVzaC5qYWluQG9vZGxlc3RlY2hub2xvZ2llcy5jb21cIixcImFyZzJcIjoyNzIxLFwiYXJnM1wiOjQ5fSIsInNjb3BlcyI6W3siYXV0aG9yaXR5IjoiUk9MRV9GRUFTIn1dLCJuYmYiOjE1ODMxNTQzNTksImlhdCI6MTU4MzE1NDM1OSwiZXhwIjoxNTgzNzYyNzU5fQ.WmLwj9yDjdgDDj4tmhlxeXNMtu349P1cSklsxpAOL-JJRPMCvAcz5XHiG4dbRN2DrgSMCfViwCJ9eDAD1EHQpg';
      
      }
    },error =>{
      console.log(error)
      this.spinner.hide();
    })
    if (this.kycform.valid) {
      this.authService.Kyc(this.kycform.value);
    }
  }
  
 
}
