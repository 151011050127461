<header >
  <div class="header">
    <div class="container-fluid own-height">
      <div class="header-wrapper">
        <div class="logo">
          <div class="logo-img">
            <img src="assets/icons/logo.png" alt="">
          </div>
        </div>
        <div class="profile">

          <div class="icon">
<input type="image" (click)="staticModal.show()" src="assets/icons/noun_notification_1334829.png">            
            <div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-static-name" class="modal-title pull-left">View all</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="staticModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="model-content-option-pl"></div>
        <div class="model-content-option-wl"></div>
        <div class="model-content-option-zl"></div>

        <label >1598.25 NVOY Successfully added</label>
        <label class="model-body-detail">1598.25 NVOY Successfully added</label>
        <label class="model-body-detail">1598.25 NVOY Successfully added</label>
      </div>
    </div>
  </div>
</div>
          </div>
           <!-- <span class="notification" >
            <svg width="10.667" height="11.811">
              <path class="a"
                d="M20.379,14.318a3.556,3.556,0,0,1-.358-1.552V10.123A4.091,4.091,0,0,0,15.893,6.28h-.085a4.084,4.084,0,0,0-4.125,3.844v2.642a3.556,3.556,0,0,1-.359,1.552l-.769,1.555a.405.405,0,0,0,.363.584h3.065a1.884,1.884,0,0,0,1.864,1.634h0a1.879,1.879,0,0,0,1.86-1.632h3.067a.405.405,0,0,0,.365-.584ZM16.6,16.965a1.058,1.058,0,0,1-.75.315h0a1.081,1.081,0,0,1-1.041-.823h2.079a1.068,1.068,0,0,1-.284.509Z"
                transform="translate(-10.514 -6.28)" />
            </svg>
          </span> -->
          <div class="user-profile">
            <!-- <div class="user-profile-img">
              <img src="assets/icons/user.png">
            </div> -->
            <div class="user-name">{{userData.fullName}}</div>

            <div class="dropdown">
              <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
              <span class="caret"></span></button>
              <ul class="dropdown-menu">
                <img  class = "pr-svg" routerLink="/profile" routerLinkActive="active" src="assets/icons/noun_profile_1602417.png">
                <img  class = "pr1-svg" routerLink="/settings" routerLinkActive="active" src="assets/icons/noun_Settings_2434659.png">
                <img class="pr2-svg"  src="assets/icons/refer.png">
                <img class="pr3-svg" (click)="onLogout()" routerLink="logIn" routerLinkActive="active" src="assets/icons/noun_logout_1262616.png">
                <li style="color:#FFFFFF; margin-left: 10px; font-size: 10px;">{{userData.email}}</li>
                <li class="pr" routerLink="/profile" routerLinkActive="active"> Profile</li>
                <li class="pr-1" routerLink="/settings" routerLinkActive="active">Deposit</li>
                <li class="pr-2">My Referral ID</li>
                <li class="pr-3" routerLink="logIn" (click)="onLogout()" routerLinkActive="active">Log out</li>
              </ul>
             </div>
            <!-- <span class="dropdown">
              <svg width="14.351" height="8.271" viewBox="0 0 14.351 8.271">
                <g transform="translate(-34.977 -41.352)">
                  <path class="a"
                    d="M42.173,49.623h0a1.068,1.068,0,0,1-.752-.3l-6.126-6.14A1.072,1.072,0,0,1,36.8,41.662l5.369,5.388,5.369-5.388a1.068,1.068,0,0,1,1.509,1.509l-6.126,6.155A1.068,1.068,0,0,1,42.173,49.623Z"
                    transform="translate(0)" />
                </g>
              </svg>
            </span> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</header>
