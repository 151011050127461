import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { SendEnvoyModel, TransactionSummary, TransactionSummaryRequsetModel, ReceiveEnvoyModel } from './payment.model';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { BalanceDataModel, MyBalanceModel } from 'src/app/common/dashboard/dashboard.model';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  convertFund: FormGroup;
  isValid = false;
  setProd: string;
  sendPayment: SendEnvoyModel;
  receivePayment: ReceiveEnvoyModel;
  transactionRequest: TransactionSummaryRequsetModel;
  transactionDetail: Array<TransactionSummary>;
  balanceDetail: Array<BalanceDataModel>;
  address: MyBalanceModel;
  token: boolean;
  duration: string;
  strictSend: boolean;
  currencyList: any;
  balance: boolean;
  marketCurrency: any;
  pairedCurrency: any;
  convertedFund: any;
  // transactionDetail: Array<TransactionSummary>;
  constructor(private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthService, ) {
    this.sendPayment = new SendEnvoyModel();
    this.transactionDetail = new Array<TransactionSummary>();
    this.transactionRequest = new TransactionSummaryRequsetModel();
    this.balanceDetail = new Array<BalanceDataModel>();
    this.address = new MyBalanceModel();
    this.balance = false;
    this.token = false;
    this.duration = null;
    this.strictSend = false;
    this.receivePayment = new ReceiveEnvoyModel();
    this.currencyList = this.getCurrencyList();
    // this.selectedValue = "--select--"
    this.marketCurrency = null;
    this.pairedCurrency = null;
    this.convertedFund = null;
  }

  ngOnInit() {
    this.spinner.show();
    this.transactionSummary();
    this.getCurrencyList();
    this.getBalance();
    this.convertFund = this.fb.group(
      {
        currencyList: ['']
      }
    );
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }
  hello = "";
  ownClass = "true";

  addCart(event) {
    this.hello = event.target.value;
  }
  getValue(vall) {
    this.setProd = vall.target.value;
  }
  show() {
    this.isValid = true;
  }

  sendEnvoy(sendPayment) {
    this.spinner.show();

    this.authService.sendEnvoy(sendPayment)
      .subscribe(
        (success: any) => {
          this.spinner.hide();
          console.log(success);
          this.transactionSummary();
          this.toastr.success(success.message);
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.messageService.showErrorMessage(error);
          this.spinner.hide();
        })
  }

  receiveEnvoy(receivePayment) {
    this.spinner.show();
    this.authService.receiveEnvoy(receivePayment)
      .subscribe(
        (success: any) => {
          this.spinner.hide();
          console.log(success);
          this.transactionSummary();
          this.toastr.success(success.message);
        }, (error: HttpErrorResponse) => {
          console.log(error);
          this.messageService.showErrorMessage(error);
          this.spinner.hide();
        })
  }

  // selected(){
  //   alert(this.selectedLevel.currencyList)
  // }

  selectChangeHandler(event: any) {
    //update the ui
    this.marketCurrency = event.target.value;
  }

  selectChangeHandlerTo(event: any) {
    //update the ui
    this.pairedCurrency = event.target.value;
  }

  getBalance() {
    this.spinner.show();
    this.authService.getBalance().subscribe(
      (success: any) => {
        this.spinner.hide();
        this.balance = true;
        console.log("get balance api call");
        this.balanceDetail = success.responseObject.listOfAccount;
        this.address = success.responseObject.address;
        console.log(this.balanceDetail, 'response')
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        // this.messageService.showErrorMessage(error);
      }
    );
  }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  onDurationClick(duration) {
    this.transactionDetail = [];
    this.transactionRequest.days = duration;
    this.transactionSummary();
  }

  getCurrencyList() {
    this.authService.getCurrencyList().subscribe(response => {
      this.currencyList = response.responseObject;
      console.log(this.currencyList);
    })
  }

  convertFunds(marketCurrency, pairedCurrency) {
    this.spinner.show();
    this.authService.convertFunds(marketCurrency, pairedCurrency).subscribe(
      (response: any) => {
        this.spinner.hide();
        console.log("convert fund api call");
        this.convertedFund = response.responseObject;
        console.log(this.convertedFund);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        this.messageService.showErrorMessage(error);
      }
    );
  }

  senttransactionSummary() {
    this.spinner.show();
    const sentData = {
      currencyCode: null,
      days: null,
      transactionStatus: null,
      transactionType: "outgoing",
    }
    this.authService.transactionSummary(sentData).subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log("transaction summary api call");
        this.transactionDetail = success.responseObject;
        console.log(this.transactionDetail, 'response')
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        this.messageService.showErrorMessage(error);
      }
    );
  }

  receivetransactionSummary() {
    this.spinner.show();
    const receiveData = {
      currencyCode: null,
      days: null,
      transactionStatus: null,
      transactionType: "incoming",
    }
    this.authService.transactionSummary(receiveData).subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log("transaction summary api call");
        this.transactionDetail = success.responseObject;
        console.log(this.transactionDetail, 'response')
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        this.messageService.showErrorMessage(error);
      }
    );
  }

  transactionSummary() {
    this.spinner.show();
    this.authService.transactionSummary(this.transactionRequest).subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log("transaction summary api call");
        this.transactionDetail = success.responseObject;
        console.log(this.transactionDetail, 'response')
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        this.messageService.showErrorMessage(error);
      }
    );
  }
}

