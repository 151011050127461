export class BuyRequestModel {
    amount: string;
    buyingAsset: string;
    price: string;
    sellingAsset: string;
    sourceAccountId: string;
    sourceAccountSeed: string;
}

export class BuyResponseModel {
    responseObject: BuyEnvoyModel;
}

export class BuyEnvoyModel {
    rateLimitLimit: number;
    rateLimitRemaining: number;
    rateLimitReset: number;
    hash: string;
    ledger: number;
    envelopeXdr: EnvelopeXdrModel;
    resultXdr: ResultXdrModel;
    extras: any;
    success: boolean;
    decodedTransactionResult: TransactionResultModel;
}

export class EnvelopeXdrModel {
    present: boolean;
}

export class ResultXdrModel {
    present: boolean;
}

export class TransactionResultModel {
    present: boolean;
}


export class SellRequestModel {
    amount: string;
    buyingAsset: string;
    price: string;
    sellingAsset: string;
    sourceAccountId: string;
    sourceAccountSeed: string;
}

export class SellResponseModel {
    responseObject: SellEnvoyModel;
}

export class SellEnvoyModel {
    rateLimitLimit: number;
    rateLimitRemaining: number;
    rateLimitReset: number;
    hash: string;
    ledger: number;
    envelopeXdr: SellEnvelopeXdrModel;
    resultXdr: SellResultXdrModel;
    extras: any;
    success: boolean;
    decodedTransactionResult: SellTransactionResultModel;
}

export class SellEnvelopeXdrModel {
    present: boolean;
}

export class SellResultXdrModel {
    present: boolean;
}

export class SellTransactionResultModel {
    present: boolean;
}

export class TradeTransactionHistoryRequest{
        amount: string;
        buyingAsset: string;
        createdOn: any;
        id: any;
        ledger: any;
        orderType: string;
        price: string;
        sellingAsset: string;
        transactionStatus: string;
        txHash: string;
        updatedOn: any; 
}

export class TradeTransactionHistoryResponseModel {
    responseObject: Array<TradeTransactionHistory>;
    constructor(){
        this.responseObject = new Array<TradeTransactionHistory>();
    }
}

export class TradeTransactionHistory{
    id: number;
    price: string;
    amount: string;
    sellingAsset: string;
    buyingAsset: string;
    sourceAccountId: string;
    txHash: string;
    ledger: number;
    transactionStatus: string;
    orderType: string;
    createdOn: string;
    updatedOn: string;
}
