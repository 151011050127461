export class BalanceData{
  responseObject: MyBalanceModel;
  constructor(){
    this.responseObject = new MyBalanceModel();
  }
}

export class MyBalanceModel {
  listOfAccount: Array<BalanceDataModel>;
  address: string;
    constructor(){
      this.listOfAccount = new Array<BalanceDataModel>();
      this.address = null;
    }
}

export class BalanceDataModel {
    assetType: string;
    assetCode: string;
    balance: any;
    constructor(){
      this.assetCode = null;
      this.assetCode = null;
      this.balance = null;
    }
}

export class TransactionSummaryRequsetModel {
  balanceTransactionId: string;
  buyingAmount: string;
  buyingCurrency: string;
  chargeId: string;
  days: string;
  paymentAmount: string;
  paymentCurrency: string;
  transactionStatus: string;
  txId: string;
  userId: any;
  constructor() {
      this.balanceTransactionId = null;
      this.buyingAmount= null;
      this.buyingCurrency = null;
      this.chargeId = null;
      this.days = null;
      this.paymentAmount = null;
      this.paymentCurrency = null;
      this.transactionStatus = null;
      this.txId = null;
      this.userId = null;
  }
}

export class TransactionSummaryResponseModel {
  responseObject: Array<TransactionSummary>;
  constructor() {
      this.responseObject = new Array<TransactionSummary>();
  }
}

export class TransactionSummary {
    id: any;
    buyingCurrency: string;
    paymentCurrency: string;
    txId: string;
    chargeId: string;
    balanceTransactionId: string;
    createdOn: string;
    updatedOn: string;
    transactionStatus: string;
    buyingAmount: any;
    paymentAmount: any;
}


