export class SendEnvoyModel {
    destinationAssetCode: string;
    senderAssetCode: string;
    amount: string;
    source: string;
    toAddress: string;
    constructor() {
        this.destinationAssetCode = null;
        this.senderAssetCode = null;
        this.amount = null;
        this.source = null;
        this.toAddress = null;
    }
}

export class ReceiveEnvoyModel {
    destinationAssetCode: string;
    senderAssetCode: string;
    amount: string;
    source: string;
    toAddress: string;
    constructor() {
        this.destinationAssetCode = null;
        this.senderAssetCode = null;
        this.amount = null;
        this.source = null;
        this.toAddress = null;
    }
}

export class TransactionSummaryRequsetModel {
    currencyCode: string;
    days: string;
    transactionStatus: string;
    transactionType: string;
    constructor() {
        this.currencyCode = null;
        this.days = null;
        this.transactionStatus = null;
        this.transactionType = null;
    }
}

export class TransactionSummaryResponseModel {
    responseObject: Array<TransactionSummary>;
    constructor() {
        this.responseObject = new Array<TransactionSummary>();
    }
}

export class TransactionSummary {
    id: number;
    memoMessage: string;
    memoId: string;
    ledger: string;
    senderAssetCode: string;
    destinationAssetCode: string;
    createdOn: any;
    updatedOn: any;
    toAddress: string;
    fromAddress: string;
    txHash: string;
    transactionStatus: string;
    amount: number;
    paymentType: string;
    uniquePaymentId: string;
    operationId: string;

}








