<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="container-fluid pt-md-3 trading pb-md-3 height-100vh pt-md-4">
  <div class="row">
    <div class="col-md-6 buy-envoy">
      <div class="wrapper-box">
        <div class="wrapper-box-content">
          <div class="mt-md-5 mb-md-4"><button class="btn btn-dark-blue width-250"><span class="pr-2">
                <img src="assets/icons/transfer.svg">
              </span> BUY NVOY</button></div>
          <div class="label-heading-1 mt-md-2 mb-md-2">With</div>
          <div class="form-group mt-md-4">
            <select class="btn btn-light-blue">
              <option>Buy Offer</option>
            </select>
          </div>
          <div class="row justify-content-center m-0">
            <div class="col-md-6">
              <div class="label-text pt-md-3">Amount</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="buyEnvoyRequest.amount" type="text" name="amount"></div>
              <div class="label-text pt-md-3">Buying Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="buyEnvoyRequest.buyingAsset" type="text" name="amount"></div>
              <!-- <div class="label-text pt-md-3">Account ID</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="buyEnvoyRequest.sourceAccountId" type="text" name="amount"></div> -->
            </div>
            <div class="col-md-6">
              <div class="label-text pt-md-3">Price</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="buyEnvoyRequest.price" type="text" name="price"></div>
              <div class="label-text pt-md-3">Selling Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="buyEnvoyRequest.sellingAsset" type="text" name="amount"></div>  
            </div>
            <div class="col-md-12">
            <div class="label-text pt-md-3">Account Seed</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="buyEnvoyRequest.sourceAccountSeed" type="text" name="amount"></div>
          </div>
              <div class="text-right label-text mb-md-3">
              ( Fee 0.00000454 Included )
            </div>
            <div class="col-md-12">
              <!-- <button class="btn btn-light-green w-100" data-toggle="modal"
                data-target="#exampleModalCenter">BUY</button> -->
                <button class="btn btn-light-green w-100 m-10" (click)="buyEnvoy(buyEnvoyRequest, accountId)">BUY</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 pl-md-0 sell-envoy">
      <div class="wrapper-box">
        <div class="wrapper-box-content">
          <div class="mt-md-5 mb-md-4"><button class="btn btn-dark-blue width-250"><span class="pr-2">
                <img src="assets/icons/transfer.svg">
              </span> SELL NVOY</button></div>
          <div class="label-heading-1 mt-md-2 mb-md-2">With</div>
          <div class="form-group mt-md-4">
            <select class="btn btn-light-blue">
              <option>Sell Offer</option>
            </select>
          </div>
          <div class="row justify-content-center m-0">
            <div class="col-md-6">
              <div class="label-text pt-md-3">Amount</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sellEnvoyRequest.amount" type="text" name="amount"></div>
              <div class="label-text pt-md-3">Buying Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sellEnvoyRequest.buyingAsset" type="text" name="amount"></div>
              <!-- <div class="label-text pt-md-3">Account ID</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="buyEnvoyRequest.sourceAccountId" type="text" name="amount"></div> -->
            </div>
            <div class="col-md-6">
              <div class="label-text pt-md-3">Price</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sellEnvoyRequest.price" type="text" name="price"></div>
              <div class="label-text pt-md-3">Selling Asset</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sellEnvoyRequest.sellingAsset" type="text" name="amount"></div>  
            </div>
            <div class="col-md-12">
            <div class="label-text pt-md-3">Account Seed</div>
              <div class="form-group"><input class="form-control own-form-control" [(ngModel)]="sellEnvoyRequest.sourceAccountSeed" type="text" name="amount"></div>
          </div>
              <div class="text-right label-text mb-md-3">
              ( Fee 0.00000454 Included )
            </div>
            <div class="col-md-12">
              <!-- <button class="btn btn-light-green w-100" data-toggle="modal"
                data-target="#exampleModalCenter">BUY</button> -->
                <button class="btn btn-light-red w-100 m-10" (click)="sellEnvoy(sellEnvoyRequest, accountId)">SELL</button>
            </div>
          </div>
          <!-- <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="label-text text-left">Amount</div>
              <div class="input-group mb-md-3">
                <input type="text" class="form-control own-form-control" placeholder="" id="" name="">
                <div class="input-group-append">
                  <select class="btn btn-light-blue">
                    <option>BTC</option>
                  </select>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="row justify-content-center">
            <div class="col-md-10">
              <div class="label-text text-left">Amount receivable</div>
              <div class="input-group mb-md-3">
                <input type="text" class="form-control own-form-control" placeholder="" id="" name="">

              </div>
              <div class="text-right label-text mb-md-3">
                ( Fee 0.00000454 Included )
              </div>
              <div class="mb-md-3">
                <button class="btn btn-light-red w-100">SELL</button>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal starts -->

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content own-modal">
      <div class="modal-header no-border d-block">
        <button type="button" class="close own-close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="text-center text-white">
          <div class="mb-md-4">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="62.924"
              height="61.434" viewBox="0 0 62.924 61.434">
              <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#f5c25b" />
                  <stop offset="1" stop-color="#f59e5b" />
                </linearGradient>
              </defs>
              <g id="Group_341" data-name="Group 341" transform="translate(-10681 -1073)">
                <path id="Path_233" data-name="Path 233"
                  d="M1034.221,1243.048c0,2.234-4.721,3.907-5.2,6-.49,2.158,3.025,5.71,2.086,7.657-.955,1.979-5.933,1.438-7.291,3.138s.265,6.441-1.443,7.805-5.951-1.278-7.93-.323c-1.947.939-2.529,5.914-4.687,6.4-2.09.475-4.767-3.744-7-3.744s-4.911,4.219-7,3.744c-2.158-.49-2.74-5.465-4.687-6.405-1.979-.955-6.23,1.681-7.93.323s-.079-6.1-1.443-7.8-6.336-1.159-7.291-3.138c-.939-1.947,2.577-5.5,2.086-7.657-.475-2.09-5.2-3.764-5.2-6s4.721-3.907,5.2-6c.49-2.158-3.025-5.71-2.086-7.657.955-1.979,5.933-1.438,7.291-3.138s-.265-6.441,1.443-7.805,5.951,1.278,7.93.323c1.947-.939,2.529-5.915,4.687-6.4,2.09-.475,4.767,3.744,7,3.744s4.911-4.219,7-3.744c2.158.49,2.74,5.466,4.687,6.4,1.979.955,6.23-1.681,7.93-.323s.079,6.1,1.443,7.8,6.336,1.159,7.291,3.139c.939,1.947-2.577,5.5-2.086,7.657C1029.5,1239.141,1034.221,1240.814,1034.221,1243.048Z"
                  transform="translate(9709.703 -139.331)" fill="url(#linear-gradient)" />
                <g id="Group_340" data-name="Group 340" transform="translate(10695.875 1090.499)">
                  <g id="Group_339" data-name="Group 339" transform="translate(0 1.482)" style="isolation: isolate">
                    <path id="Path_234" data-name="Path 234"
                      d="M1052.111,1320.151l-12.093-11.9,4.128-4.02,7.965,7.8,17.076-16.84,3.919,3.871Z"
                      transform="translate(-1040.018 -1295.198)" fill="#e79b38" />
                  </g>
                  <path id="Path_235" data-name="Path 235"
                    d="M1052.96,1318.82l-10.675-10.675,4.057-4.057,6.618,6.618,16.059-16.059,4.057,4.057Z"
                    transform="translate(-1041.319 -1294.648)" fill="#f5e48c" />
                </g>
              </g>
            </svg>
          </div>
          <div class="mb-md-4">
            Transaction successful
          </div>
          <div class="mb-md-5">
            You have received <span class="label-text-bold"> {{buyEnvoyDetail.ledger}} NVOY</span> in your wallet.
          </div>
          <div class="mb-md-3">
            <button class="btn btn-light-blue w-70">Done</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>


<!-- Table -->
<div class="col-md-7">
<div class="t-1">
  <div class="h-1">Transaction Summary</div>
    <div class="h-2">Sent</div>
    <div class="h-3">Received</div>
    <div class="bt-1 ">
      <button class="btn btn-light-blue w-100" class="btnall">All</button>
    </div>
    <div class="bt-2">
      <select class="form-control btn-light-blue" class="export">
        <option>Export to CSV</option>
      </select>
    </div>
  <div class="h-4"><a class="h4-4">1D</a></div>
  <div class="h-5"><a class="h5-5">1W</a></div>
  <div class="h-6"><a class="h6-6">1M</a></div>
  <div class="h-7"><a class="h7-7">1Y</a></div>
  <div class="h-8"><a class="h8-8">MAX</a></div>
  <!-- <div class="t-2">
    <div class="d-1">Date & Time</div>
    <div class="d-2">Method</div>
    <div class="d-3">Type</div>
    <div class="d-6">currency</div>
    <div class="d-4">Price</div>
    <div class="d-5">Amount</div>
    <div class="d-7">Status</div>
</div> -->
<!-- <div class="a-1">2020-01-13 01:23:54</div>
<div class="a-2">2020-01-13 01:23:54</div>
<div class="a-3">2020-01-13 01:23:54</div>
<div class="a-4">2020-01-13 01:23:54</div>
<div class="a-5">2020-01-13 01:23:54</div>
<div class="c-1">Crypto</div>
<div class="c-2">Flat</div>
<div class="c-3">Flat</div>
<div class="c-4">Crypto</div>
<div class="c-5">Flat</div>
<div class="btn1-1" >
  <button class="btn btn-light-green w-100" class="btnall-1" data-toggle="modal"
    data-target="#">BUY</button>
</div>
<div class="btn2-2" >
  <button class="btn btn-light-green w-100" class="btnall-2" data-toggle="modal"
    data-target="#">SELL</button>
</div>
<div class="btn3-3" >
  <button class="btn btn-light-green w-100" class="btnall-3" data-toggle="modal"
    data-target="#">SELL</button>
</div>
<div class="btn4-4" >
  <button class="btn btn-light-green w-100" class="btnall-4" data-toggle="modal"
    data-target="#">BUY</button>
</div>
<div class="btn5-5" >
  <button class="btn btn-light-green w-100" class="btnall-5" data-toggle="modal"
    data-target="#">SELL</button>
</div>
<div class="e-1">BTC</div>
<div class="e-2">XRP</div>
<div class="e-3">DTH</div>
<div class="e-4">BTC</div>
<div class="e-5">XRP</div>
<div class="f-1">$ 656. 14</div>
<div class="f-2">$ 653. 41</div>
<div class="f-3">$63.00</div>
<div class="f-4">$ 636. 41</div>
<div class="f-5">$63.00</div>
<div class="g-1">456 NVOY</div>
<div class="g-2">1824 USD</div>
<div class="g-3">4241 USD</div>
<div class="g-4">456 NOVY</div>
<div class="g-5">1824 USD</div>
<div class="s-1">Completed</div>
<div class="s-2">Completed</div>
<div class="s-3">Rejected</div>
<div class="s-4">Completed</div>
<div class="s-5">Completed</div> -->

<div class="t-2">
  <div id="table-wrapper">
    <div id="table-scroll">
      <table>
        <thead>
          <tr>
            <th class="w20 pl"><label class="text">Date & Time</label></th>
            <th class="w15 pl"><label class="text">Buying Currency</label></th>
            <th class="w15 pl"><label class="text">Selling Currency</label></th>
            <th class="w15 pl"><label class="text">Type</label></th>
            <th class="w15 pl"><label class="text">Price</label></th>
            <th class="w15 pl"><label class="text">Amount</label></th> 
            <th class="w15 pl"><label class="text">Status</label></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let trade of tradeDetail">
            <td class="w20">
              <label class="f-label">{{trade.createdOn | date:'medium'}}</label>
            </td>
            <td class="w20">
              <label class="f-label">{{trade.buyingAsset}}</label>
            </td>
            <td class="w20">
              <label class="f-label">{{trade.sellingAsset}}</label>
            </td>
            <td class="w20">
              <!-- <label class="f-label">{{trade.orderType}}</label> -->
              <!-- <button class="btn btn-light-red w-20" *ngIf="trade.orderType == 'SELL_OFFER'">Sell</button> -->
              <label class="f-label buy-green w-20" *ngIf="trade.orderType == 'BUY_OFFER'">Buy</label>
              <label class="f-label sell-red w-20" *ngIf="trade.orderType == 'SELL_OFFER'">Sell</label>
            </td>
            <td class="w20">
              <label class="f-label">{{trade.price}}</label>
            </td>
            <td class="w20">
              <label class="f-label">{{trade.amount}}</label>
            </td>
            <td class="w20">
              <!-- <label class="f-label">{{trade.transactionStatus}}</label> -->
              <label class="f-label red " *ngIf="trade.transactionStatus == 'REJECTED'">Rejected</label>
              <label class="f-label blue " *ngIf="trade.transactionStatus == 'CONFIRMED'">Completed</label>
              <label class="f-label red" *ngIf="trade.transactionStatus == 'CANCEL'">Cancelled</label>
              <label class="f-label blue" *ngIf="trade.transactionStatus == 'PENDING'">Pending</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>
</div>