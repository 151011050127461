import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ChangePasswordDetails } from './changePassword.model';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
  })
export class ChangePasswordComponent implements OnInit {
  changePswdform: FormGroup;
  changePasswordData: ChangePasswordDetails = new ChangePasswordDetails();
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private messageService : MessageService,
    private spinner: NgxSpinnerService,
  ) {}
  
  ngOnInit() {
    this.changePswdform = this.fb.group({
      oldpassword: ['', [Validators.required, Validators.minLength(6)]],
      newpassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword:['',[Validators.required, Validators.minLength(6)]]
    });
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }
  // onSubmit() {
  //   console.log(this.changePswdform.value)
  //   if (this.changePswdform.valid) {
  //     this.authService.forgot(this.changePswdform.value);
  //   }
  // }

  changePassword(userData) {
    this.spinner.show();
    this.authService.changePassword(userData)
    .subscribe(
      (success: any) => {
        
        this.router.navigateByUrl('logIn');
        this.toastr.success(success.message);
        this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
        this.spinner.hide();
      })
  }
 
}

