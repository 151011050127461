<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="container-fluid">
  <div class="logo">
    <div class="logo-img">
      <img src="assets/icons/logo.png" alt="">
    </div>
  </div>
  <div class="logo-1">
    <div class="logo-img-1">
      <img src="assets/icons/Asset 1.png" alt="">
    </div>
  </div>
  <div class="logo-2">
    <div class="logo-img-2">
      <img src="assets/icons/Group 381@2x.png" alt="">
    </div>
  </div>
  <div class="border-form">
    <div class="heading-1"><b>Welcome Folk</b></div>
    <div class="description-1">Welcome to Enjoy chain, Please login with Your</div>
    <div class="description-2">personal account information letter</div>
    <div class="box">
      <form [formGroup]="form" #loginForm="ngForm">
      <div class="input-container-2">		
        <input type="email" formControlName="email" [(ngModel)]="loginData.email" autocomplete="off" required/>
        <label>Email Address</label>
        <div class="text-danger color-primary"
        *ngIf="form.controls['email'].touched && !form.controls['email'].valid">
        <span *ngIf="form.controls['email'].hasError('required') ">
            Email is required
        </span>
        <span  *ngIf="form.controls['email'].hasError('pattern') ">
            Invalid Email Address
        </span>
    </div>
      
        
      </div>
      <div class="input-container-3">		
        <input type="password" formControlName="password" [(ngModel)]="loginData.password"autocomplete="off" required/>
        <label>Password</label>
        <div class="text-danger color-primary"
        *ngIf="form.controls['password'].touched && !form.controls['password'].valid">
        <span *ngIf="form.controls['password'].hasError('required') ">
            password is required
        </span>

        <span *ngIf="form.controls['password'].hasError('pattern') ">
         Enter Strong password (like- Envoy@123)
      </span>   
    </div>


      </div>
        
    <label class="container-1">
      <input type="checkbox" checked="checked">
      <span class="checkmark"></span>
    </label>
    <label for="remember_me" class="remember">Remember me</label>
    <label for="forgotpassword"  routerLink="/forgot-pswd" class="f_psd">Forgot password?</label>
    <div class="col-md-6 ">
      <button type="submit" [disabled]=!loginForm.valid class="btn login-btn" (click)="onSubmit(loginData)">Sign In</button>
      <button routerLink="/signup" routerLinkActive="active" class="btn-1" >Sign Up</button>
      </div>
    </form>	
  </div>

      <div class="input_3"></div>
      <div class="or">Or</div>
      <div class="input_4"></div> 
     <a href="https://myaccount.google.com/"> <button class="btn-2" >Sign in with Google</button></a>
      <div class="logo-3">
        <div class="logo-img-3">
          <img src="assets/icons/google-hangouts.png" alt="">
        </div>
      
  </div>
  
</div>
