import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { kycRegistrationDetails } from '../models/kycRegistrationDetails';
import { data } from 'jquery';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;

@Component({
  selector: 'app-kycRegistration',
  templateUrl: './kycRegistration.component.html',
  styleUrls: ['./kycRegistration.component.scss']
})
export class kycRegistrationComponent implements OnInit {
  kycRegistrationform: FormGroup;
  submitted: boolean;
  kycRegistrationData = new kycRegistrationDetails();
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router :Router,
    private spinner: NgxSpinnerService
  ) {}
  emailregex = /^[a-z]+[a-z0-9._+a-z]+@[a-z0-9]+\.[a-z.]{2,}$/
  
  ngOnInit() {
    this.kycRegistrationData = new kycRegistrationDetails();
    this.kycRegistrationform = this.fb.group({
    email: ['', [Validators.required, Validators.email,Validators.pattern(this.emailregex)]],
     
      
    });
    this.spinner.show();
 
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }
  onSubmit(kycRegistrationDetails) {
    this.spinner.show();
    this.authService.tokenuser(kycRegistrationDetails).subscribe((res)=>{
      console.log(JSON.stringify(res))
      localStorage.setItem('token', res.responseObject.token)
      if(res){
        // let token = localStorage.getItem('token');
        this.router.navigate(['/kyc'])
        this.spinner.hide();
      }
    },error =>{
      console.log(error)
      this.spinner.hide();
    })
    if (this.kycRegistrationform.valid) {
      this.authService.Kyc(this.kycRegistrationform.value);
    }
  }
 
}
