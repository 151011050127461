export class DepositResponseModel {
    responseObject: DepositModel;
}

export class DepositModel {
    buyingCurrency: string;
    calculatePrice: any;
    paymentCurrency:string;
}

export class ChargePaymentRequestModel{
        amount: string;
        buyingCurrency: string;
        email: string;
        token: string; 
        paymentCurrency: string;  
}