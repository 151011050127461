<app-header></app-header>
<app-sidebar></app-sidebar>
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<div class="container-fluid">
  <div class="border-form">
    <div class="heading-1"><b>KYC </b></div>
    <div class="box">
      <form [formGroup]="kycRegistrationform" (ngSubmit)="onSubmit(kycRegistrationData)">
      <div class="input-container-2">		
        <input type="email" formControlName="email" [(ngModel)]="kycRegistrationData.email" autocomplete="off" required/>
        <label>Email</label>
        <div class="text-danger color-primary"
        *ngIf="kycRegistrationform.controls['email'].touched && !kycRegistrationform.controls['email'].valid">
        <span *ngIf="kycRegistrationform.controls['email'].hasError('required') ">
        email is required
        </span>
        <span *ngIf="kycRegistrationform.controls['email'].hasError('pattern') ">
          email is invalid
          </span>
       </div>
      </div>
     <div class="col-md-6 ">
     <button  class="btn-1" >Submit</button>
      </div>
    </form>	
  </div>
</div>