import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { ChargePaymentRequestModel } from './deposit.model';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  paymentDetails: ChargePaymentRequestModel;
  email: any;
  // chargeAmount: any;
  // currency: any;
  // token: any;

  constructor(private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private messageService : MessageService,
    private authService: AuthService,) { 
      this.paymentDetails = new ChargePaymentRequestModel();
      this.email = null;
    }
  handler:any = null;
  amount: string = null;
  currency: any = null;
  responseObject: any;
  amountToPay: any=null;
  cardtoken: any = null;
 


  ngOnInit() {
    this.spinner.show();
    this.loadStripe();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
    
  }

  deposit(amount, currency){
    this.spinner.show();
    this.authService.deposit(amount,currency)
    .subscribe(
      (success: any) => {
        this.spinner.hide();
        this.pay(this.amountToPay);
        this.paymentDetails.amount = (success.responseObject.calculatePrice).toString();
        this.paymentDetails.buyingCurrency = success.responseObject.buyingCurrency;
        this.paymentDetails.email = this.email;
        this.paymentDetails.paymentCurrency = success.responseObject.paymentCurrency;
        // var amountpayable = success.responseObject.calculatePrice;
        // var buyingCurrency = success.responseObject.buyingCurrency;
        // var paymentCurrency = success.responseObject.paymentCurrency;
        // var mail = this.email;
        // localStorage.setItem('mail', mail);
        // localStorage.setItem('amount', amountpayable);
        // localStorage.setItem('currency', buyingCurrency);
        // localStorage.setItem('paycurrency', paymentCurrency);
        console.log(success);
        console.log(this.paymentDetails.amount);
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
        this.spinner.hide();
      })
  }

  chargePayment(){
    this.spinner.show();
    // var chargeAmount = localStorage.getItem('amount');
    // var email = localStorage.getItem('mail');
    // var currency = localStorage.getItem('currency');
    // var token = localStorage.getItem('cardtoken');
    this.authService.chargePayment(this.paymentDetails)
    .subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log(success);
        // console.log(token);
        
        this.toastr.success(success.message);
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.messageService.showErrorMessage(error);
        this.spinner.hide();
      })
  }

  pay(amount) {    
    let self= this;
    var amountToPay = localStorage.getItem('amount');
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_test_3fvlarcvAPkv2Z7INm8xHrRY00jOpUI8A2',
      locale: 'auto',
      token: function (token: any) {
        self.paymentDetails.token = token.id;
        self.chargePayment();
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        var cardtoken = localStorage.setItem('cardtoken', token.id);
        // this.chargePayment();

        console.log(token.id)
        console.log(amountToPay);
      }
    });
 
    handler.open({
      name: 'Payment',
      description: 'Bank Transaction',
      amount: amount * 100
    });
 
  }
 
  loadStripe() {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_3fvlarcvAPkv2Z7INm8xHrRY00jOpUI8A2',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token.id);
            alert('Payment Success!!');
          }
        });
      }
       
      window.document.body.appendChild(s);
    }
  }



}
