<div class="row">
  <div class="col-md-12">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">

              <div class="row">
                <div class="col-md-12">
                  <div class="logo">
                    <div class="logo-img">
                      <img src="assets/icons/logo.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="logo-1">
                    <div class="logo-img-1">
                      <img src="assets/icons/Asset 1.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="logo-2">
                    <div class="logo-img-2">
                      <img src="assets/icons/Group 381@2x.png" alt="">
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <div class="border-form">
                <div class="heading-1"><b>Sign up</b></div>
                <div class="description-1">Welcome to Enjoy chain, Please login with Your</div>
                <div class="description-2">personal account information letter</div>
                <div class="box">
                  <form [formGroup]="registerform" (ngSubmit)="onSubmit(SignupData)" #signUpForm="ngForm">
                    <div class="input-container-1">
                      <input type="text" formControlName="fullName" [(ngModel)]="SignupData.fullName" autocomplete="off"
                        name="fullName" required>
                      <label>Full Name</label>
                      <div class="text-danger color-primary"
                        *ngIf="registerform.controls['fullName'].touched && !registerform.controls['fullName'].valid">
                        <span *ngIf="registerform.controls['fullName'].hasError('required') ">
                          Name is required
                        </span>
                        <span *ngIf="registerform.controls['fullName'].hasError('pattern') ">
                  Please Enter Valid Name
              </span>

                      </div>

                    </div>
                    <div class="input-container-2">
                      <input type="email" formControlName="email" [(ngModel)]="SignupData.email" autocomplete="off"
                        name="email" required>
                      <label>Email Address</label>
                      <div class="text-danger color-primary"
                        *ngIf="registerform.controls['email'].touched && !registerform.controls['email'].valid">
                        <span *ngIf="registerform.controls['email'].hasError('required') ">
                          Email is required
                        </span>
                        <span *ngIf="registerform.controls['email'].hasError('pattern') ">
                          Invalid Email Address
                        </span>
                      </div>


                    </div>
                    <div class="input-container-3">
                      <input type="password" formControlName="password" [(ngModel)]="SignupData.password"
                        autocomplete="off" name="password" required>
                      <label>Password</label>
                      <div class="text-danger color-primary"
                        *ngIf="registerform.controls['password'].touched && !registerform.controls['password'].valid">
                        <span *ngIf="registerform.controls['password'].hasError('required') ">
                          Password is required
                        </span>
                        <span *ngIf="registerform.controls['password'].hasError('pattern') ">
                          Enter Strong password (like- Envoy@123)
                        </span>

                      </div>


                    </div>
                    <div class="input-container-4">
                      <input type="password" formControlName="confirmPassword" [(ngModel)]="SignupData.confirmPassword"
                        autocomplete="off" name="confirmpassword" required>
                      <label>Confirm Password</label>
                      <div class="text-danger color-primary"
                        *ngIf="registerform.controls['confirmPassword'].touched && !registerform.controls['confirmPassword'].valid">
                        <span *ngIf="registerform.controls['confirmPassword'].hasError('required') ">
                          Confirm Password is required
                        </span>
                        <span *ngIf="registerform.controls['confirmPassword'].hasError('mustMatch') ">
                          Passwords does not match
                        </span>

                      </div>

                    </div>


                    <label class="container-1">
                      <input type="checkbox" checked="checked">
                      <span class="checkmark"></span>
                    </label>
                    <label for="remember_me" class="remember">I agree all statements in <u style="color: #37BFFF;">Terms
                        of
                        service</u></label>
                    <div class="col-md-6">
                      <button type="submit" class="btn-1" [disabled]="!signUpForm.valid">Sign Up</button>
                      <button routerLink="/logIn" routerLinkActive="active" class="btn">Sign In</button>
                 
                    </div>
                  </form>
                </div>
              
                <div class="input_3"></div>
                <div class="or">Or</div>
                <div class="input_4"></div>
                <a href="https://account.google.com/"><button class="btn-2">Sign in with Google</button></a>
                <div class="logo-3">
                  <div class="logo-img-3">
                    <img src="assets/icons/google-hangouts.png" alt="">
                  </div>

                </div>
                

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>