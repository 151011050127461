import { Component, OnInit } from '@angular/core';
import { MyBalanceModel, BalanceDataModel, TransactionSummaryRequsetModel, TransactionSummary } from './dashboard.model';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/services/message.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  balanceDetail: Array<BalanceDataModel>;
  accountBalance: boolean;
  transactionRequest: TransactionSummaryRequsetModel;
  transactionDetail: Array<TransactionSummary>;

  constructor(private authService: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService) {
    this.balanceDetail = new Array<BalanceDataModel>();
    this.accountBalance = false;
    this.transactionDetail = new Array<TransactionSummary>();
    this.transactionRequest = new TransactionSummaryRequsetModel();
  }

  ngOnInit() {
    this.getBalance();
    this.spinner.show();
    this.depositTransactionSummary();
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 2000);
  }

  getBalance() {
    this.spinner.show();
    this.authService.getBalance().subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log("get balance api call");
        this.accountBalance = true;
        this.balanceDetail = success.responseObject.listOfAccount;
        console.log(this.balanceDetail, 'response')
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        // this.messageService.showErrorMessage(error);
      }
    );
  }

  onDurationClick(duration) {
    this.transactionDetail = [];
    this.transactionRequest.days = duration;
    this.depositTransactionSummary();
  }

  depositTransactionSummary() {
    this.spinner.show();
    this.authService.depositTransactionSummary(this.transactionRequest).subscribe(
      (success: any) => {
        this.spinner.hide();
        console.log(" deposit transaction summary api call");
        this.transactionDetail = success.responseObject;
        console.log(this.transactionDetail, 'response')
        console.log(success);
        // this.toastr.success(success.message);
      },
      (error: HttpErrorResponse) => {
        console.log(error);
        this.spinner.hide();
        //  this.messageService.showErrorMessage(error);
      }
    );
  }

}
